/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 6/25/2020
 * @Example
 */


import React, {Component} from "react";
import validator from 'validator';
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W39F1030Actions from "../../../../redux/W3X/W39f1030/W39F1030_actions";
import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import {Col, Row, FormGroup} from "react-bootstrap";
import {Combo, TextField} from "../../../common/form-material";
import ButtonGeneral from "../../../common/button/button-general";
import CreateIcon from '@material-ui/icons/Create';
import {
    FormControl,
    InputLabel,
    Typography,
    OutlinedInput
} from "@material-ui/core";
import Attachments from "../../../common/attachments/attachments";
import _ from "lodash";
import NumberFormat from 'react-number-format';
import {browserHistory} from "react-router";
import moment from 'moment';
const styles = theme => ({
    caption: {
        paddingTop: "7%",
        [theme.breakpoints.down("md")]: {
            paddingTop: 0,
        },
    },
    textEvaluation: {
        display: "inline-block",
        color: "#333333",
        fontSize: "15px",
        marginTop: "25px",
        textAlign: "center",
    }

});

class W39F1030 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: {},
            disabled: false,
            loadingListAppChildCriterion: false,
            dataOldAttachments: [],
            uploading: false,
            formLoading: false,
            formValid: [],
            listGroups: [],
            dataMaster: {},
            isUpdated: false,
            NoteEvaluatedLevel1: "",
            RefEvaluation01U: "",
            formData : {},

        };
        this.attachments = [];
        this.deletedFile = [];
        this.upload = null;
        this.timerInput= null;
        this.allowCalculate = false;
    }

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({['loading' + key]: status});

    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;
        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (!_.isEmpty(dataOldAttachments)) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    onUploading = value => {
        this.setState({ uploading: value,isUpdated: true });
    };

    onChangeAttachments = e => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map(d => d.AttachmentID);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter(att => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            }),() => {
                this.setState({isUpdated: true});
            });
        }
    };

    loadFormGrid = () => {
        const {  EvaluationVoucherID,DepartmentID , mode, TransID } = this.getInfo();
        const params = {
            EvaluationVoucherID: EvaluationVoucherID,
            TransID: TransID,
            DepartmentID: DepartmentID,
            FormID: "W39F1030",
            Language: Config.language || '84',
            Mode: mode && mode === "add" ? 0 : 1 ,
        };
        this.setState({formLoading: true});
        this.props.W39F1030Actions.getLoadFormGrid(params, (error,data) => {
            this.setState({formLoading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if(data){
                this.setState({
                    NoteEvaluatedLevel1: data.NoteEvaluatedLevel1,
                    formData: {
                        ...this.state.formData,
                        RefEvaluation01U: data.RefEvaluation01U,
                        Result: data.Result,
                        DesResult: data.DesResult
                    }
                });
            }

        });
    };

    loadListAppChildCriterion = () => {
        const {  EvaluationVoucherID,DepartmentID , mode } = this.getInfo();
        const params = {
            EvaluationVoucherID: EvaluationVoucherID,
            DepartmentID: DepartmentID,
            FormID: "W39F1030",
            Language: Config.language || '84',
            Mode: mode && mode === "add" ? 0 : 1 ,
        };
        this.setLoading('ListAppChildCriterion', true);
        this.props.W39F1030Actions.getLoadListAppChildCriterions(params, (error, data) => {
            this.setLoading('ListAppChildCriterion', false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }

            let listGroups = [];
            const dataMaster = {};
            const formData = {};
            if (data) {
                data.forEach(d => {
                    if (!listGroups.find(group => group.AppCriterionGroupID === d.AppCriterionGroupID)) {
                        listGroups.push(d);
                        dataMaster[d.AppCriterionGroupID] = [];
                    }
                    dataMaster[d.AppCriterionGroupID].push(d);
                    //formData...
                    formData[d.EvaluationElementID] = {
                        EvaluationElementID: d.EvaluationElementID,
                        TypeID: d.TypeID,
                        Value: d.Value,
                        Note1U: d.Note1U,
                    };
                });
            }
            this.setState({
                listGroups,
                dataMaster,
                formData: {
                    ...this.state.formData,
                    ...formData
                }
            });
        });
    };

    loadCboAppChildCriterion = () => {
        const {  EvaluationVoucherID,DepartmentID  } = this.getInfo();
        const params = {
            EvaluationVoucherID: EvaluationVoucherID,
            DepartmentID: DepartmentID,
            FormID: "W39F1030",
            Language: Config.language || '84',
        };
        this.setState({formLoading: true});
        this.props.W39F1030Actions.getCboAppChildCriterion(params, (error, data) => {
            this.setState({formLoading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    onChange = (name, e, type) => {
        if (!name || !e) return false;
        if (!this.state.isUpdated) this.setState({isUpdated : true});
        const {formData} = this.state;
        const {getCboAppChildCriterion} = this.props;

        let value = _.get(e, "value", _.get(e, "target.value", ""));
        switch (type) {
            case "combo":
                const dataSourceCbo = getCboAppChildCriterion.filter(dCbo => dCbo.EvaluationElementID === name);
                const itemSelected = dataSourceCbo.find(d => d.EvaluationLevelID === value);
                const _value = itemSelected && itemSelected.Value ? itemSelected.Value : 0;
                this.setState({
                    formData: {
                        ...formData,
                        [name]: {
                            ...formData[name],
                            TypeID: value ? value : "",
                            Value: _value,
                        }
                    }
                });
                break;
            case "number":
                if (this.timerInput) clearTimeout(this.timerInput);
                this.timerInput = setTimeout(() => {
                    this.setState({
                        formData: {
                            ...formData,
                            [name]: {
                                ...formData[name],
                                Value: value,
                            }
                        }
                    });
                }, 400);
                break;
            case "text":
                if (this.timerInput) clearTimeout(this.timerInput);
                this.timerInput = setTimeout(() => {
                    this.setState({
                        formData: {
                            ...formData,
                            [name]: {
                                ...formData[name],
                                Note1U: value,
                            }
                        }
                    });
                }, 400);
                break;
            case "textarea":
                if (this.timerInput) clearTimeout(this.timerInput);
                this.timerInput = setTimeout(() => {
                    this.setState({[name]: value});
                }, 400);
                break;
            default:
                break;
        }

    };

    onChangeResult = (e, type) => {
        if (!this.state.isUpdated) this.setState({isUpdated : true});
        const {formData} = this.state;
        let value = _.get(e, "value", _.get(e, "target.value", ""));
        if (!type) {
            this.setState({
                formData: {
                    ...formData,
                    Result: value
                }
            });
        } else {
            this.setState({
                formData: {
                    ...formData,
                    [type]: value
                }
            });
        }
    };

    onSave = () => {
        const {  EvaluationVoucherID,DepartmentID , mode, TransID } = this.getInfo();
        if (this.state.formValid && this.state.formValid.length > 0) {
            Config.popup.show("INFO", Config.lang("DHR_Tham_so_truyen_vao_khong_hop_le"));
            return false;
        }

        const arrAttachments = this._getAttachments();
        const {formData, NoteEvaluatedLevel1 } = this.state;
        let data = [];
        const listExcludeFields = ['NoteEvaluatedLevel1', 'RefEvaluation01U', 'Result', 'DesResult'];
        Object.keys(formData).forEach(key => {
            if (!listExcludeFields.includes(key)) {
                const item = formData[key];
                data.push({
                    EvaluationElementID: key,
                    TypeID:              item.TypeID,
                    Value:               item.Value && item.Value.Value ? item.Value.Value : item.Value,
                    Note1U:              item.Note1U
                });
            }
        });
        const params = {
            EvaluationVoucherID: EvaluationVoucherID,
            DepartmentID: DepartmentID,
            Result: formData && (formData.Result || formData.Result === 0) ? formData.Result : null,
            DesResult: formData && formData.DesResult ? formData.DesResult : "",
            TranMonth: Config.getHRTransMonth(),
            TranYear: Config.getHRTransYear(),
            DivisionID: Config.getDivisionID(),
            Mode: mode && mode === "add" ? 0 : 1 ,
            FormID: "W39F1030",
            Language: Config.language || '84',
            TransID: TransID,
            NoteEvaluatedLevel1: NoteEvaluatedLevel1,
            arrAttachment: arrAttachments ? JSON.stringify(arrAttachments) : "",
            data: JSON.stringify(data)
        };
        this.setState({formLoading: true});
        this.props.W39F1030Actions.save(params, (error,data) => {
            this.setState({formLoading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.loadFormGrid();
            this.loadAttachments();
            this.loadListAppChildCriterion();
            this.setState({isUpdated: false});
            this.allowCalculate = true;
            Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 2000);
        });
    };

    loadAttachments = () => {
        const {TransID} = this.getInfo();
        const param = {
            KeyID : TransID,
            TableName: "D39T2002"
        };
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data,
                    loading:false,
                });
            }
            this.setState({
                loading:false,
            });
        });
    };


    getInfo = (flag, nextProps) => {
        const { location } = nextProps ? nextProps : this.props;
        if (location && location.state) {
            const voucher_id = location.state.voucher_id ? location.state.voucher_id : "";
            const state = location.state;
            return {
                ...state,
                mode: location.state.mode ? location.state.mode : (voucher_id ? 'edit' : 'add'),
            }
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + "W39F1025");
                return null;
            }
            return false;
        }
    };

    componentDidMount = async () => {
        if (!this.getInfo()) return;
        const {  mode } = this.getInfo();
        if( mode === "edit") {
            this.loadAttachments();
        }
        this.loadFormGrid();
        this.loadCboAppChildCriterion();
        this.loadListAppChildCriterion();
    };

    onBlur = (value, name, rule, data) => {
        if (rule === "Point") {
            this.validCriterionPoint(name, value, data);
        }
    };

    validCriterionPoint = (name, value, data) => {
        const {formValid} = this.state;
        const val = value ? value : "0";
        let _formValid = formValid.filter(f => f.field && f.criterionID !== data.EvaluationElementID);
        const isInt = validator.isInt(val, {min: 0});
        if (!isInt) {
            _formValid.push({
                field: name,
                criterionID: data.EvaluationElementID,
                error: true,
                message: Config.lang("DHR_Diem_so_khong_hop_le")
            });
        }
        this.setState({formValid: _formValid});
    };

    isError = (name, rule, data) => {

        const {formValid} = this.state;
        if (rule === "Point") {
            for (let i = 0; i < formValid.length; i++) {
                const fvl = formValid[i];
                if (fvl.field === name && fvl.criterionID === data.EvaluationElementID) {
                    return fvl.error;
                }
            }
        }

        return false;
    };
    getErrorMessage = (name, rule, data) => {
        const {formValid} = this.state;
        if (rule === "Point") {
            for (let i = 0; i < formValid.length; i++) {
                const fvl = formValid[i];
                if (fvl.field === name && fvl.criterionID === data.EvaluationElementID) {
                    return fvl.message;
                }
            }
        }
        return "";
    };

    onBack = () => {
        const state = this.getInfo();
        browserHistory.push({
            pathname: Config.getRootPath() + (state && state.FormID ? state.FormID : ""),
            state: {
                ...state,
                mode: "edit"
            }
        });
    };

    render() {
        if (!this.getInfo(true)) return null;
        const { mode, DepartmentID, DepartmentName, CreateDate, TransTypeName, StatusID} = this.getInfo();
        const { formLoading, listGroups, dataMaster, dataOldAttachments, formData, isUpdated, loadingListAppChildCriterion,
                  NoteEvaluatedLevel1 } = this.state;
        const { RefEvaluation01U} = formData;
        const { getLoadFormGrid, classes, getCboAppChildCriterion } = this.props;
        return (
            <>
                <ActionToolbar  title={Config.lang("DHR_Danh_gia_phong_ban")} onBack={this.onBack} alignment={"flex-end"}>
                    <ButtonGeneral name={Config.lang('DHR_Luu')}
                                   typeButton={'save'}
                                   disabled={Number(StatusID) === 1 || getLoadFormGrid.IsSave === 0 || (this.allowCalculate ? this.allowCalculate : !isUpdated ) || formLoading}
                        // loading={formLoading}
                                   style={{ textTransform: 'uppercase' }}
                                   size={"large"}
                                   onClick={this.onSave} />
                </ActionToolbar>
                <Typography style={{fontSize: "15px", margin: "15px 0", fontWeight:"bold"}}>
                    {TransTypeName +' '+  DepartmentName + ' - '+ DepartmentID}
                </Typography>
                <Typography style={{fontSize: "15px",  margin: "15px 0", fontWeight:"bold"}}>
                    {  moment.utc(CreateDate).format("DD/MM/YYYY, kk:mm:ss") }
                </Typography>
                {listGroups.map((item, indx) => {
                    const data = dataMaster[item.AppCriterionGroupID];
                    return (
                        item.IsMiddleEvaluationElement === 0 &&
                        <div key={indx} style={{border: "1px solid #ddd", borderRadius: "5px", margin: "15px 1px", padding: "15px"}}>
                            <Typography style={{fontSize: "15px", fontWeight:"bold"}}>
                                {item.AppCriterionGroupNameU}
                            </Typography>
                            {data && data.map((d, indx2) => {
                                const dataSourceCbo = getCboAppChildCriterion.filter(dCbo => dCbo.EvaluationElementID === d.EvaluationElementID);
                                const rowData = formData && formData[d.EvaluationElementID] ? formData[d.EvaluationElementID] : null;
                                let typeText = d.ResultDes || "";
                                if (!typeText) {
                                    switch (d.IsTypeEvaluation) {
                                        case 1: typeText = Config.lang("DHR_Xep_loai"); break;
                                        case 2: typeText = Config.lang("DHR_Cong_thuc"); break;
                                        default: typeText = Config.lang("DHR_Diem"); break;
                                    }
                                }
                                return (
                                    <Row key={indx2} >
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant={"standard"}
                                                         margin={"normal"}
                                                         fullWidth={true}>
                                                <InputLabel
                                                    shrink={true}
                                                >
                                                    {d.OrderNo + '.' + d.EvaluationElementName + ' (' + d.Proportion + ')'}
                                                </InputLabel>

                                                <Row>
                                                    <Col xs={12} sm={4} md={4} lg={4}>
                                                        <Row>
                                                            <Col xs={12} sm={8} md={8} lg={8}>
                                                                {d.IsTypeEvaluation === 1 ?
                                                                    <Combo
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                            style: { marginTop: "10px" }
                                                                        }}
                                                                        selectProps={{
                                                                            readOnly: (getLoadFormGrid.IsSave === 1 ? false : true)
                                                                        }}
                                                                        margin={"normal"}
                                                                        disabled={formLoading}
                                                                        showClearButton={true}
                                                                        stylingMode={"underlined"}
                                                                        dataSource={dataSourceCbo}
                                                                        displayExpr={"EvaluationLevelNameU"}
                                                                        valueExpr={"EvaluationLevelID"}
                                                                        onValueChanged={e => this.onChange(d.EvaluationElementID, e, "combo")}
                                                                        value={(rowData && String(rowData.TypeID))}
                                                                    />
                                                                    : d.IsTypeEvaluation === 2 ?
                                                                        <TextField
                                                                            variant={"standard"}
                                                                            margin={"normal"}
                                                                            type={"number"}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            disabled={formLoading}
                                                                            inputProps={{ min: "0", step: "1", inputComponent: NumberFormatCustom,
                                                                                readOnly : true }}

                                                                            error={this.isError(d.EvaluationElementID, "Point", d)}
                                                                            helperText={this.getErrorMessage(d.EvaluationElementID, "Point", d)}
                                                                            onBlur={(e) => this.onBlur(e.target.value, d.EvaluationElementID, "Point", d)}
                                                                            value={mode === "add" && !this.allowCalculate ? "" : (rowData && String(rowData.Value))}
                                                                            fullWidth
                                                                            onChange={e => this.onChange(d.EvaluationElementID, e, "number")}
                                                                        />
                                                                        :
                                                                        <TextField
                                                                            variant={"standard"}
                                                                            margin={"normal"}
                                                                            type={"number"}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            disabled={formLoading}
                                                                            inputProps={{ min: "0", step: "1" , readOnly: (getLoadFormGrid.IsSave === 1 ? false : true)}}
                                                                            error={this.isError(d.EvaluationElementID, "Point", d)}
                                                                            helperText={this.getErrorMessage(d.EvaluationElementID, "Point", d)}
                                                                            onBlur={(e) => this.onBlur(e.target.value, d.EvaluationElementID, "Point", d)}
                                                                            defaultValue={mode === "add" ? "" : (rowData && String(rowData.Value))}
                                                                            fullWidth
                                                                            onChange={e => this.onChange(d.EvaluationElementID, e, "number")}
                                                                        />
                                                                }

                                                            </Col>
                                                            <Col xs={12} sm={4} md={4} lg={4}>
                                                            <span className={classes.textEvaluation}>
                                                                {'(' + typeText + ')'}
                                                            </span>

                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={12} sm={8} md={8} lg={8}>
                                                        <TextField
                                                            variant={"standard"}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            disabled={formLoading}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <CreateIcon style={{fontSize: 16, color: "#9E9E9E"}}/>
                                                                ),
                                                                readOnly: (getLoadFormGrid.IsSave === 1 ? false : true)
                                                            }}
                                                            // disabled={disabled}
                                                            defaultValue={rowData ? String(rowData.Note1U) : ""}
                                                            onChange={(e) => this.onChange(d.EvaluationElementID, e,  "text")}
                                                            margin={"normal"}
                                                            multiline
                                                            fullWidth
                                                        />
                                                    </Col>
                                                </Row>

                                            </FormControl>

                                        </Col>
                                    </Row>
                                );
                            })}
                        </div>
                    )
                })}

                <FormGroup>
                    <div style={{border: "1px solid #ddd", borderRadius: "5px", margin: "15px 1px", padding: "15px"}}>
                        <Typography style={{fontSize: "15px", fontWeight:"bold"}}>
                            {Config.lang("DHR_Ket_qua_danh_gia")}
                        </Typography>
                        <Row>
                            <Col xs={12} sm={4} md={4} lg={4}>
                                <Row>
                                    <Col xs={12} sm={8} md={8} lg={8}>
                                        <Combo
                                            InputLabelProps={{
                                                shrink: true,
                                                style:  {marginTop: "10px"}
                                            }}
                                            selectProps={{
                                                readOnly: getLoadFormGrid && getLoadFormGrid.IsSave !== 1
                                            }}
                                            margin={"normal"}
                                            disabled={formLoading}
                                            showClearButton={true}
                                            stylingMode={"underlined"}
                                            dataSource={[{key: 1, value: Config.lang("DHR_Dat")}, {key: 0, value: Config.lang("DHR_Khong_dat")}, ]}
                                            displayExpr={"value"}
                                            valueExpr={"key"}
                                            onValueChanged={e => this.onChangeResult(e)}
                                            value={formData && formData.Result}
                                        />
                                    </Col>
                                    <Col xs={12} sm={4} md={4} lg={4} />
                                </Row>
                            </Col>
                            <Col xs={12} sm={8} md={8} lg={8}>
                                <TextField
                                    variant={"standard"}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    disabled={formLoading}
                                    InputProps={{
                                        startAdornment: (
                                                            <CreateIcon style={{fontSize: 16, color: "#9E9E9E"}}/>
                                                        ),
                                        readOnly: (getLoadFormGrid.IsSave === 1 ? false : true)
                                    }}
                                    // disabled={disabled}
                                    defaultValue={formData && formData.DesResult ? String(formData.DesResult) : ""}
                                    onChange={(e) => this.onChangeResult(e,  "DesResult")}
                                    margin={"normal"}
                                    multiline
                                    fullWidth
                                />
                            </Col>
                        </Row>
                    </div>
                </FormGroup>

                {loadingListAppChildCriterion === false && (
                    <>
                        <FormGroup>
                            <Row>
                                <Col md={3} xs={12}>
                                    <div className={classes.caption}>
                                        <Typography align="left" component="h6">
                                            {Config.lang('DHR_Danh_gia_chung')}
                                        </Typography>
                                    </div>
                                </Col>
                                <Col md={9} xs={12}>
                                    <OutlinedInput
                                        fullWidth={true}
                                        defaultValue={NoteEvaluatedLevel1}
                                        onChange={(e) => this.onChange("NoteEvaluatedLevel1",e,'textarea')}
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                            "aria-label": "weight",
                                            readOnly: (getLoadFormGrid.IsSave === 1 ? false : true)
                                        }}
                                        disabled={formLoading}
                                        multiline={true}
                                        labelWidth={0}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col md={3} xs={12}>
                                    <div className={classes.caption}>
                                        <Typography align="left" component="h6">
                                            {Config.lang('DHR_Ghi_chu')}
                                        </Typography>
                                    </div>
                                </Col>
                                <Col md={9} xs={12}>
                                    <OutlinedInput
                                        fullWidth={true}
                                        value={RefEvaluation01U}
                                        onChange={(e) => this.onChange("RefEvaluation01U",e,'textarea')}
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                            "aria-label": "weight",
                                            readOnly: true
                                        }}
                                        disabled={formLoading}
                                        multiline={true}
                                        labelWidth={0}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} style={{zIndex: 1}}>
                                    <Attachments
                                        isAttachInfo
                                        ref={ref => (this.attRef = ref)}
                                        showButton={true}
                                        files={dataOldAttachments}
                                        disabled={Number(StatusID) === 1 || formLoading}
                                        maxLength={5}
                                        onUploading={this.onUploading}
                                        onChanged={this.onChangeAttachments}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                        <ButtonGeneral name={Config.lang('DHR_Luu')}
                                       typeButton={'save'}
                                       disabled={Number(StatusID) === 1 || getLoadFormGrid.IsSave === 0 || (this.allowCalculate ? this.allowCalculate : !isUpdated ) || formLoading }
                                       style={{ textTransform: 'uppercase',float: 'right' }}
                                       size={"large"}
                                       onClick={this.onSave} />
                    </>
                )}

            </>
        )
    }
}

class NumberFormatCustom extends Component {

    render() {
        const {name, inputRef, onChange, ...props} = this.props;
        return (
            <NumberFormat
                {...props}
                // value={value}
                getInputRef={inputRef}
                thousandSeparator={true}
                onValueChange={(e) => {
                    if (onChange) {
                        onChange({
                            target: {
                                name: name,
                                value: e.value
                            }
                        })
                    }
                }}
            />
        );
    }
}

export default compose(
    connect(
        state => ({
            getLoadFormGrid: state.W39F1030.getLoadFormGrid,
            getLoadListAppChildCriterion: state.W39F1030.getLoadListAppChildCriterion,
            getCboAppChildCriterion: state.W39F1030.getCboAppChildCriterion
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W39F1030Actions: bindActionCreators(W39F1030Actions, dispatch)
        })
    ),
    withStyles(styles, {withTheme: true})
)(W39F1030);