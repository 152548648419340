/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 7/3/2020
 */

import React, { Component } from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { IconButton, withStyles, Tooltip } from "@material-ui/core";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W39F1010Actions from "../../../../redux/W3X/W39F1010/W39F1010_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Modal from "../../../common/modal/modal";
import { FormGroup, Row, Col, Image } from "react-bootstrap";
import GridContainer from "../../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Filter from "../../../filter/filter";
import Config from "../../../../config";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import moment from "moment";
import _ from "lodash";
import ButtonCustom from "../../../common/button/button-custom";
import { Combo } from "../../../common/form-material";

import W39F1012 from "../W39F1012/W39F1012";
import Status from "../../../common/status/status";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005";
import {ButtonIcon, Checkbox} from "diginet-core-ui/components";
import Icon from "diginet-core-ui/icons";

const styles = (theme) => ({
    headerTitle: {
        "& > :last-child": {
            justifyContent: "flex-end !important",
        },
    },
    divDateText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#000000",
    },
    dateInfo: {
        display: "flex",
        alignItems: "center",
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757",
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB",
        color: theme.palette.grey["600"],
        "&.statusColor": {
            color: theme.palette.success.main,
        },
    }
});

const arrStar = new Map([
    [0, 'StarOutline'],
    [0.5, 'StarHalf'],
    [1, 'Star'],
]);

class W39F1010 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gridLoading: false,
            cBoLoading: false,
            employeeLoading: false,
            disabledCboEmployee: false,
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
            dataCboStatus: [
                {
                    StatusID: "PROCESS",
                    StatusName: Config.lang("Dang_thuc_hien"),
                },
                {
                    StatusID: "COMPLETE",
                    StatusName: Config.lang("Hoan_thanh"),
                },
            ],
            rowData: null,
            isMineVoucher: false,
            showW39F1012: false,
            w84F3005Popup: null,
            filterEmployee: null,
        };
        this.filter = {
            TransTypeID: "",
            StatusID: "",
            skip: 0,
            limit: 10,
        };
        this.filterCboEmployees = {
            timer: null,
            search: "",
            skip: 0,
            limit: 50,
        };
        this.defaultParams = {
            FormID: "W39F1010",
            Language: Config.language || "84",
        };
        this.menuType = Number(Config.getLocalStorage("MENUTYPEDHR") ?? 0);
    }

    setMineVoucher = () => {
        const { isMineVoucher } = this.state;
        this.setState({ isMineVoucher: !isMineVoucher }, this.loadFormGrid);
    };

    handleResponse = (err, data = []) => {
        if (err) Config.popup.show("ERROR", err);
        if (data === "isGrid") {
            this.setState({
                gridLoading: false,
            });
        } else {
            this.setState({ cBoLoading: false });
        }
    };

    loadFormGrid = (voucher_id) => {
        const { isMineVoucher, filterEmployee } = this.state;
        const {location} = this.props;
        const params = {
            ...this.defaultParams,
            ..._.omit(this.filter, "employee"),
            EmployeeID: filterEmployee?.EmployeeID ?? "",
            DivisionID: Config.getDivisionID(),
            IsMineVoucher: location?.state?.menu?.Type === "ESS" ? 1 : Number(isMineVoucher),
            TransID: voucher_id || ""
        };
        this.setState({ gridLoading: true });
        this.props.w39f1010Actions.getFormGrid(params, (err) => this.handleResponse(err, "isGrid"));
    };

    loadCboTransType = () => {
        this.setState({ cBoLoading: true });
        this.props.w39f1010Actions.getTransType(this.handleResponse);
    };

    loadStatus = () => {
        const { dataCboStatus } = this.state;
        this.setState({ cBoLoading: true });
        this.props.w39f1010Actions.getStatus((err, data) => {
            this.handleResponse(err);
            if(data?.IsNewMethod) {
                this.setState({
                    dataCboStatus: [
                        ...dataCboStatus,
                        {StatusID: "NEW", StatusName: Config.lang("Chua_danh_gia")}
                    ]})
            }
        });
    };

    loadCboEmployees = (isReset) => {
        const param = {
            HostID: "",
            Type: "EmployeeID",
            ...this.defaultParams,
            ..._.omit(this.filterCboEmployees, ["timer","strSearch"]),
            search: this.filterCboEmployees.strSearch,
            Disabled: +(this.state?.disabledCboEmployee ?? false),

        };
        this.setState({ cBoLoading: true });
        this.props.generalActions.getCboEmployees(param, (err, data) => {
            this.handleResponse(err);
            if (data) {
                const { dataCboEmployees } = this.state;
                const rows = _.get(data, "rows", []);
                const total = _.get(data, "total", data.length);
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total,
                    },
                });
            }
        });
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadFormGrid();
    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadFormGrid();
    };

    onCloseModal = () => {
        this.loadFormGrid();
        this.setState({
            showW39F1012: false,
        });
    };

    renderEmpProfile = (e) => {
        const { classes } = this.props;
        const { data } = e.row;
        const objRenderDate = {
            content: moment.utc(data.CreateDate).format("LT, DD/MM/YYYY"),
            isVisible: Boolean(data.CreateDate),
        };
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} />
                <div className={"w75f2000_info"}>
                    <UserName data={data} />
                    {objRenderDate.isVisible && (
                        <div className={classes.dateInfo}>
                            <Image src={require("../../../../assets/images/icon-calendar.svg")} />
                            <div style={{ paddingLeft: "8px" }} className={"date-text-info"}>
                                {objRenderDate.content}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    renderInfo = (e) => {
        const { data } = e.row;
        const { classes } = this.props;
        const arrRenderInfo = [
            {
                content: `${Config.lang("Thoi_gian")}: ${moment.utc(data.AppraisalDate).format("DD/MM/YYYY")}`,
                isVisible: _.get(data, "AppraisalDate", false),
            },
            {
                content: `${Config.lang("Loai_danh_gia")}: ${data.TransTypeName}`,
                isVisible: _.get(data, "TransTypeName", false),
            },
            {
                content: `${Config.lang("Ghi_chu")}: ${data.Note}`,
                isVisible: _.get(data, "Note", false),
            },
        ].filter((item) => Boolean(item.isVisible));
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                {arrRenderInfo.map(({ content }, i) => {
                    const preWarpNote = i === arrRenderInfo.length - 1 ? { style: { whiteSpace: "pre-wrap" } } : {};
                    return (
                        <div key={i} {...preWarpNote}>
                            {content}
                        </div>
                    );
                })}
            </div>
        );
    };

    renderStatus = (e) => {
        const { data } = e.row;
        const StatusID = _.get(data, "StatusID", "PROCESS");
        const listStatus = {
            "PROCESS": {id: 0, name: Config.lang("Dang_thuc_hien"), icon: "HourGlass"},
            "NEW": {id: 1, name: Config.lang("Chua_danh_gia"), icon:  "Loading", color: Config.coreTheme.colors.system.rest},
            "COMPLETE": {id: 2, name: Config.lang("Hoan_thanh"), icon: "Approval"},
        };
        const status = {
            AppStatusID: listStatus[StatusID]?.id,
            AppStatusName: listStatus[StatusID]?.name,
            AppStatusIcon: listStatus[StatusID]?.icon || "",
            AppStatusColor: listStatus[StatusID]?.color || ""
        };
        return (
            <Status data={status} />
        );
    };

    renderActions = (e) => {
        const { data } = e.row;
        const isApproved = Number(_.get(data, "IsApproved", 0));
        const isMultiLevel = Number(_.get(data, "IsMultiLevel", 1));
        const evaluatedStatus = _.get(data, "EvaluatedStatus", 0);
        const approveStatus = _.get(data, "ApproveStatus", 0);
        let iconApproval = (
            <Icon viewBox
                  name="Approval"
                  color={approveStatus ? "info" : "disabled"}
            />
        );
        if (!Boolean(isApproved)) {
            iconApproval = !Boolean(isMultiLevel) ? (
                <ButtonIcon name={"Edit"}
                            circular={true}
                            viewType={"text"}
                            onClick={() => this.setState({ rowData: data, showW39F1012: true })}
                />
            ) : null;
        }

        return (
            <div className={"display_row align-center valign-middle"}>
                <ButtonIcon name={arrStar.get(Number(evaluatedStatus)/2 ?? 0)}
                            viewType={'text'}
                            circular={true}
                            className={'mgr5'}
                            color={"warning"}
                            onClick={()=>this.redirectScreen(1, e)}
                />
                <ButtonIcon circular={true}
                            viewType={"text"}
                            name={"History"}
                            className={!!iconApproval ? "mgr5" : "" }
                            onClick={() => this.onHistory(data)}
                />
                {iconApproval}
            </div>
        );
    };

    onLoadDataFilter = () => {
        this.loadCboTransType();
        this.loadCboEmployees();
    };

    handleFilterChange = (key, data) => {
        const value = _.get(data, "value", "");
        if (key === "employee") {
            if (!value) {
                this.filterCboEmployees.strSearch = "";
                this.loadCboEmployees(true);
            }
            this.setState({ filterEmployee: value ?? "" });
        } else {
            this.filter = {
                ...this.filter,
                [key]: value,
            };
        }
    };

    redirectScreen = (mode = 0, e = "") => {
        const {location} = this.props;
        browserHistory.push({
            pathname: Config.getRootPath() + "W39F1011",
            state: {
                ...location?.state,
                Mode: mode,
                FormID: this.defaultParams.FormID,
                EmployeeID: _.get(e, "data.EmployeeID", "03991"),
                TransID: _.get(e, "data.TransID", "39EVCP700000004_03991"),
                EvaluationVoucherID: _.get(e, "data.EvaluationVoucherID", "39EVCP700000004"),
                ApproveLevel: _.get(e, "data.ApproveLevel", 0),
                IsSave: _.get(e, "data.IsSave", 0),
                UserLevel: _.get(e, "data.UserLevel", 1),
            },
        });
    };

    renderFilters = () => {
        const { getTransType } = this.props;
        const { cBoLoading, dataCboEmployees, dataCboStatus, disabledCboEmployee, filterEmployee } = this.state;
        const { TransTypeID, StatusID } = this.filter;
        return (
            <Filter
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                onOpenLoaded={this.onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            dataSource={getTransType}
                                            displayExpr={"TransTypeName"}
                                            valueExpr={"TransTypeID"}
                                            stylingMode={"outlined"}
                                            value={TransTypeID}
                                            disabled={cBoLoading}
                                            label={Config.lang("Phieu_danh_gia")}
                                            onValueChanged={(e) => this.handleFilterChange("TransTypeID", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            dataSource={dataCboStatus}
                                            displayExpr={"StatusName"}
                                            valueExpr={"StatusID"}
                                            stylingMode={"outlined"}
                                            value={StatusID}
                                            disabled={cBoLoading}
                                            label={Config.lang("Trang_thai")}
                                            onValueChanged={(e) => this.handleFilterChange("StatusID", e)}
                                        />
                                    </Col>
                                </Row>
                                {!!this.menuType && <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Checkbox
                                            label={Config.lang('Da_nghi_viec')}
                                            checked={disabledCboEmployee}
                                            onChange={e => this.setState({ disabledCboEmployee: e?.value ?? false })}
                                        />
                                    </Col>
                                </Row>}
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={"EmployeeID"}
                                            valueExpr={"EmployeeID"}
                                            value={filterEmployee}
                                            loading={cBoLoading}
                                            stylingMode={"outlined"}
                                            label={Config.lang("Nhan_vien")}
                                            showClearButton={true}
                                            onValueChanged={(e) => this.handleFilterChange("employee", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonCustom
                                    name={Config.lang("Tim_kiem")}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"text"}
                                    onClick={()=>{
                                        this.filter.skip=0;
                                        this.loadFormGrid();
                                    }}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    onHistory = (data = {}) => {
        this.setState({w84F3005Popup: data.TransID || null})
    };


    render() {
        const { classes, getFormGrid } = this.props;
        const { gridLoading, isMineVoucher, showW39F1012, rowData, w84F3005Popup } = this.state;
        return (
            <>
                <Modal
                    open={showW39F1012}
                    maxWidth={"md"}
                    fullWidth={true}
                    title={Config.lang("Cap_nhat_thong_tin_khac_cho_phieu_danh_gia")}
                    onClose={this.onCloseModal}
                >
                    <W39F1012 data={rowData} onClose={this.onCloseModal} />
                </Modal>
                <div className={"hidden"}>{this.renderFilters()}</div>
                {!!w84F3005Popup && <W84F3005 open={!!w84F3005Popup}
                                              customTitle={Config.lang("Lich_su_danh_gia")}
                                              onClose={()=>this.onHistory()}
                                              FormID="W39F1010" TransID={w84F3005Popup}/>
                }
                <FormGroup>
                    <ActionToolbar className={classes.headerTitle} title={Config.lang("Phieu_danh_gia_nhan_vien")}>
                        <Tooltip title={Config.lang("Phieu_cua_toi")}>
                            <IconButton size="small" onClick={this.setMineVoucher}>
                                <AccountCircleIcon color={isMineVoucher ? "primary" : "inherit"} fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </ActionToolbar>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <GridContainer
                                style={{ border: "none" }}
                                hoverStateEnabled={true}
                                dataSource={_.get(getFormGrid, "rows", [])}
                                keyExpr={"TransID"}
                                totalItems={_.get(getFormGrid, "total", 0)}
                                itemPerPage={this.filter.limit}
                                skipPerPage={this.filter.skip}
                                height={Config.getHeightGrid()}
                                typePaging={"remote"}
                                loading={gridLoading}
                                typeShort={window.innerWidth < 768}
                                showRowLines={true}
                                showBorders={false}
                                showColumnLines={false}
                                showColumnHeaders={false}
                                rowAlternationEnabled={false}
                                noDataText={Config.lang("No_data")}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                            >
                                <Column cellRender={this.renderEmpProfile} width={340} />
                                <Column cellRender={this.renderInfo} />
                                <Column cellRender={this.renderStatus} width={160} />
                                <Column cellRender={this.renderActions} width={150} />
                            </GridContainer>
                        </Col>
                    </Row>
                </FormGroup>
            </>
        );
    }

    componentDidMount = () => {
        const {voucher_id} = Config.getUrlParams(this.props);
        this.loadFormGrid(voucher_id);
        this.loadStatus();
    };

    componentDidUpdate = (prevProps, prevState) => {
        const {getFormGrid} = this.props;
        Config.callChildForm({
            FormID: "W39F1010",
            keyExpr: "TransID",
            data: getFormGrid.rows || [],
            onLoad: (params) => this.loadFormGrid(params && params.ID),
            onAction: (e, data) => this.redirectScreen(1, {data: data})
        }, this.props);
        if ( prevState?.disabledCboEmployee !== this.state?.disabledCboEmployee ) {
            this.setState({ filterEmployee: null });
            this.loadCboEmployees(true);
        }
    }
}

export default compose(
    connect(
        (state) => ({
            getCboEmployees: state.general.getCboEmployees,
            getTransType: state.W39F1010.getTransType,
            getFormGrid: state.W39F1010.getFormGrid,
        }),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w39f1010Actions: bindActionCreators(W39F1010Actions, dispatch),
        }),
    ),
    withStyles(styles, { withTheme: true }),
)(W39F1010);
