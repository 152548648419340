/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 7/7/2020
 * @Example
 */

import React, {Component} from "react";

import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import * as W39F1025Actions from "../../../../redux/W3X/W39F1025/W39F1025_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import {Col, Row, FormGroup} from "react-bootstrap";
import {Combo, TextField} from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import ButtonGeneral from "../../../common/button/button-general";
import moment from 'moment';
import IconButton from "@material-ui/core/IconButton";
import W39F1031Popup from "../W39F1031/W39F1031Popup";
import W09F6500             from "../../../W0X/W09/W09F6500/W09F6500";
import {
    FormControl,
    InputLabel,
    Typography
}                           from "@material-ui/core";
import MForm                from "../../../common/form-material/form";
import {browserHistory}     from "react-router";
import GridContainer        from "../../../grid-container/grid-container";
import {Column}             from "devextreme-react/data-grid";
import ButtonCustom         from "../../../common/button/button-custom";
import Modal                from "../../../common/modal/modal";
import BorderColorIcon      from '@material-ui/icons/BorderColor';
import * as W39F1020Actions from "../../../../redux/W3X/W39F1020/W39F1020_actions";
const styles = theme => ({
    ExpansionPanelDetails: {
        padding: 0
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757"
    },
    statusColor: {
        display: "inline-block",
        color: "#333333",
        fontSize: ".9rem",
        marginTop: "21px",
        textAlign: "center",
        padding: "12px 5px",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",

        "&.success": {
            backgroundColor: theme.palette.success.main
        },
        "&.warning": {
            backgroundColor: theme.palette.warning.main
        }
    },
    numberStatus: {
        display: "inline-block",
        marginTop: "40px",
        color: theme.palette.danger.main
    }
});

class W39F1025 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            dataCboProjects: {
                rows: [],
                total: 0
            },
            dataGrid: [],
            error: {},
            loadingProjects: false,
            count: null,
            disableSave: false,
            modeEdit: false,
            loadingDataGrid: false,
            showW39F1031Popup: false,
            showW09F6500Popup: false,
            showDepartmentSelect: false,
            isUpdated: 0,
            formData: {
                VoucherDate: moment().format("YYYY-MM-DD"),
                PerformancePeriod: 0,
                PerformanceDateFrom: null,
                PerformanceDateTo: null,
                TransTypeID: "",
                StatusID: 0,
                Note: "",
                IsEvaluation: 0,
                DepartmentID: "",
                DepartmentName: "",
                TransID: "",
                IsDepartment: 0,
                IsRemove: 0
            },
            rowData: null
        };
        this.VoucherID = "";

    }

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({['loading' + key]: status});

    };


    onUploading = value => {
        this.setState({ uploading: value });
    };

    loadCboAppStatus = () => {
        const param = {
            Language: Config.language || '84',
            FormID: "W39F1020"
        };
        this.props.generalActions.getCboAppStatus(param, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadEvaluationType = () => {
        const param = {
            TranMonth: Config.getHRTransMonth(),
            TranYear: Config.getHRTransYear(),
            TransTypeID: "",
            FormID: "W39F1025"
        };
        this.props.W39F1025Actions.getEvaluationType(param, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadDetail = (_voucherID) => {
        const { EvaluationVoucherID } = this.getInfo();

        const param = {
            EvaluationVoucherID: _voucherID ? _voucherID : EvaluationVoucherID,
        };
        this.setLoading("DataGrid", true);
        this.props.W39F1025Actions.getDetail(param, (error, data) => {
            this.setLoading("DataGrid", false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if(data) {
                this.setState({dataGrid: data});
            }
        });
    };


    handleChange = (key, e) => {
        const { getEvaluationType } = this.props;
        let { formData } = this.state;
        let { PerformanceDateFrom, PerformanceDateTo, PerformancePeriod } = formData;
        if (!key) return false;
        if (this.state.isUpdated === 0) this.setState({isUpdated: 1});
        switch (key) {
            case "TransTypeID": {
                if(e.value) {
                    let getDateTime = getEvaluationType.find(item => item.TransTypeID === e.value);
                    this.setState({
                        formData : {
                            ...this.state.formData,
                            TransTypeID: e.value,
                            PerformanceDateFrom: Config.convertDate(getDateTime?.PerformanceDateFrom, null, "DD/MM/YYYY"),
                            PerformanceDateTo: Config.convertDate(getDateTime?.PerformanceDateTo, null, "DD/MM/YYYY"),
                            PerformancePeriod: getDateTime?.PerformancePeriod || 0
                        }
                    });
                }
                break;
            }
            case "StatusID": {
                this.setState({
                    formData : {
                        ...this.state.formData,
                        StatusID: e.value
                    }
                });
                break;
            }
            case "VoucherDate": {
                this.setState({
                    formData : {
                        ...this.state.formData,
                        VoucherDate: e.value
                    }
                });
                break;
            }
            case "PerformancePeriod": {
                // if(PerformanceDateFrom !== null && e.value === null) {
                //
                // }
                if (PerformanceDateFrom !== null) {
                    //  PerformanceDateTo = PerformanceDateFrom + PerformancePeriod - 1 day
                    PerformanceDateTo = moment(PerformanceDateFrom).add(e.target.value, 'months').subtract(1,'day');
                    this.setState({
                        formData : {
                            ...this.state.formData,
                            PerformanceDateTo: PerformanceDateTo,
                            PerformancePeriod: e.target.value
                        }
                    });
                    break;
                }

                if (PerformanceDateTo !== null && PerformanceDateFrom === null) {
                    //  PerformanceDateFrom = PerformanceDateTo - PerformancePeriod + 1 day
                    PerformanceDateFrom = moment(PerformanceDateTo).subtract(e.target.value, 'months').add(1, 'day');
                    this.setState({
                        formData : {
                            ...this.state.formData,
                            PerformanceDateFrom :  PerformanceDateFrom,
                            PerformancePeriod: e.target.value
                    }
                    });
                    break;
                }
                this.setState({
                    formData : {
                        ...this.state.formData,
                        PerformancePeriod: e.target.value
                    }
                });
                break;
            }
            case "PerformanceDateFrom": {
                if (PerformanceDateTo !== null) {
                    //  PerformanceDateTo = PerformanceDateFrom + PerformancePeriod - 1 day
                    PerformancePeriod = (e.value !==null) ?  moment(PerformanceDateTo).add(1, 'day').diff(e.value, 'months') : "";
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            PerformancePeriod: PerformancePeriod
                        }
                    });
                }

                if (PerformanceDateTo === null && PerformancePeriod  !== "" ) {
                    //  PerformanceDateTo = PerformanceDateFrom + PerformancePeriod - 1 day
                    PerformanceDateTo = moment(e.value).add(PerformancePeriod, 'months').subtract(1, 'day');
                    this.setState({
                        formData: {
                    ...this.state.formData,
                            PerformanceDateTo : PerformanceDateTo
                    }
                    });
                }
                this.setState({
                    formData : {
                        ...this.state.formData,
                        PerformanceDateFrom: e.value
                    }

                });
                break;
            }
            case "PerformanceDateTo": {
                if (PerformanceDateFrom !== null ) {
                     //PerformancePeriod:  = [PerformanceDateTo + 1 (day)] - PerformanceDateFrom
                    PerformancePeriod = (e.value !== null) ? moment(e.value).add(1, 'day').diff(PerformanceDateFrom, 'months'): "";
                    this.setState({
                        formData : {
                            ...this.state.formData,
                            PerformancePeriod: PerformancePeriod
                        }
                    });
                }
                if (PerformanceDateFrom === null && PerformancePeriod !== "") {
                    // PerformanceDateFrom: = PerformanceDateTo - PerformancePeriod + 1 (day)
                    PerformanceDateFrom = moment(e.value).subtract(PerformancePeriod, 'months').add(1, 'day');
                    this.setState({
                        formData : {
                            ...this.state.formData,
                            PerformanceDateFrom: PerformanceDateFrom
                        }
                    });
                }
                this.setState({
                    formData : {
                        ...this.state.formData,
                        PerformanceDateTo: e.value
                    }
                });
                break;
            }
            case "Note": {
                this.setState({
                    formData : {
                        ...this.state.formData,
                        Note: e.target.value
                    }
                });
                break;
            }
            default:
                break;
        }
    };

    setStateErrorText = (value) => {
        const { error } = this.state;
        this.setState({
            error: {
                ...error,
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    };

    onSave = () => {

        let { formData, dataGrid } = this.state;
        const {  VoucherDate, PerformancePeriod, PerformanceDateFrom, PerformanceDateTo, TransTypeID, StatusID, Note} = formData;
        const { mode, EvaluationVoucherID } = this.getInfo();
        const validateRules = [
            {
                name: "TransTypeID",
                rules: "isRequired",
                value: TransTypeID
            },
            {
                name: "PerformancePeriod",
                rules: "isRequired",
                value: PerformancePeriod
            },
            {
                name: "VoucherDate",
                rules: "isRequired",
                value: VoucherDate
            },
            {
                name: "StatusID",
                rules: "isRequired",
                value: StatusID
            }

        ];

        const validateFrom = MForm.formValidation(validateRules);
        if (Object.keys(validateFrom).length > 0) {

            this.setStateErrorText(validateFrom);
            return false;
        }
        let params = {
            TranMonth: Config.getHRTransMonth(),
            TranYear: Config.getHRTransYear(),
            DivisionID: Config.getDivisionID(),
            NoteU: Note ? Note : "",
            TransTypeID: TransTypeID ? TransTypeID : "",
            VoucherDate: VoucherDate ?  moment(VoucherDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
            PerformancePeriod: PerformancePeriod ? PerformancePeriod : 0,
            PerformanceDateFrom: PerformanceDateFrom ? moment(PerformanceDateFrom).format("YYYY-MM-DD") : null,
            PerformanceDateTo: PerformanceDateTo ?  moment(PerformanceDateTo).format("YYYY-MM-DD") : null,
            StatusID: StatusID ? String(StatusID) : "0",
            ProjectID: "",
            Mode: 0,
            data : JSON.stringify(dataGrid)
        };

        if (!(moment(params.PerformanceDateFrom) <= moment(params.VoucherDate) <= moment(params.PerformanceDateTo))) {
            Config.popup.show('INFO', Config.lang("Ban_vui_long_kiem_tra_Ngay_danh_gia_phai_trong_khoang_tu_den"));
            return false;
        }

        if (String(mode) === "edit") {
            params.EvaluationVoucherID = EvaluationVoucherID;
            params.VoucherDate = moment(VoucherDate).format("YYYY-MM-DD");
            params.Mode = 1;
        }

        this.props.W39F1025Actions[mode === "add" ? 'add' : 'edit'](params, (errors, data) => {
            this.setState({ formLoading: false });
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            Config.notify.show("success", Config.lang("Luu_thanh_cong"), 1000);
            this.setState({disableSave: true});
            this.loadDetail(data.EvaluationVoucherID);
        });

    };

    getInfo = (flag, nextProps) => {
        const { location } = nextProps ? nextProps : this.props;
        let initialState = {isNotify: false};
        if (location && location.state) {
            const state = location.state;
            if (state && state.voucher_id) {
                return {
                    ...initialState,
                    ...state,
                    isNotify: true,
                }
            } else {
                return {
                    ...initialState,
                    ...state,
                    StatusID:     location.state.StatusID ? location.state.StatusID : "0",
                    CreateUserID: location.state.CreateUserID ? location.state.CreateUserID : "",
                    mode:         location.state.mode ? location.state.mode : "",
                }
            }
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + "W39F1020");
                return null;
            }
            return false;
        }
    };

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W39F1020", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    loadDataGridW39F1020 = (voucherID) => {
        const param = {
            DivisionID: Config.getDivisionID() ? Config.getDivisionID() : "QT" ,
            VoucherDateFrom: null,
            VoucherDateTo: null,
            EvaluationVoucherID: voucherID,
            StatusID: "",
            DepartmentID: "",
            FormID: "W39F1020",
            skip: 0,
            limit: 10
        };
        this.setState({ gridLoading: true });
        this.props.W39F1020Actions.getGrid(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ gridLoading: false });
            data = data && data.rows ? data.rows : data ;
            if(data && data.length > 0) {
                data = data[0];
                this.loadDetail(data.EvaluationVoucherID);
                this.setState({
                    modeEdit : true,

                    formData: {
                        ...this.state.formData,
                        VoucherDate: moment.utc(data.VoucherDate).format("YYYY-MM-DD"),
                        PerformancePeriod: data.PerformancePeriod,
                        PerformanceDateFrom: data.PerformanceDateFrom,
                        PerformanceDateTo: data.PerformanceDateTo,
                        TransTypeID: data.TransTypeID,
                        StatusID: data.StatusID,
                        Note: data.Note,
                    }
                }, () => {
                    browserHistory.push({
                        pathname: Config.getRootPath() + "W39F1025",
                        state: {
                            mode: "edit",
                            ...data
                        }
                    });
                });
            }
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.iPermission <= 0) return;

        if (!this.getInfo()) return;
        const state = this.getInfo();
        this.loadCboAppStatus();
        this.loadEvaluationType();
        if (state.isNotify) {
            const {voucher_id} = state;
            this.loadDataGridW39F1020(voucher_id);
        } else {
            if(state && state.mode === "edit" && Number(state.StatusID) < 2) {
                this.loadDetail();
                this.setState({
                    modeEdit : true,

                    formData: {
                        ...this.state.formData,
                        VoucherDate: moment.utc(state.VoucherDate).format("YYYY-MM-DD"),
                        PerformancePeriod: state.PerformancePeriod,
                        PerformanceDateFrom: state.PerformanceDateFrom,
                        PerformanceDateTo: state.PerformanceDateTo,
                        TransTypeID: state.TransTypeID,
                        StatusID: state.StatusID,
                        Note: state.Note,
                    }
                });
            }
        }
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const state = this.getInfo();
        if (state.isNotify && state.voucher_id && this.VoucherID !== state.voucher_id) {
            this.VoucherID = state.voucher_id;
            this.checkNotify();
        }
    }

    componentWillUnmount () {
        this.VoucherID = "";
    }

    checkNotify = () => {
        const state = this.getInfo();
        if (state.isNotify && state.voucher_id) {
            const {voucher_id} = state;
            this.loadDataGridW39F1020(voucher_id);
        }
    };

    getDepartmentName = (data) => {
        if (!data) return "";
        if (data.DepartmentName) return data.DepartmentName;
        const departmentName = Config.language === "84" ? data.DepartmentNameU : data.DepartmentName01U;
        return departmentName ? departmentName : Config.lang("Khong_ten");
    };

    renderItem = (e) => {
        let { data } = e.row;
        data = {
            ...data,
            DepartmentName: this.getDepartmentName(data)
        };
        let date = moment().format("DD/MM/YYYY, kk:mm:ss");
        return (
            <>
                <Typography variant="h6">{data.DepartmentName +" - "+ data.DepartmentID}</Typography>
                <div>{date}</div>
                {data && data.DesResult && <div>{Config.lang("Ket_qua")}:&nbsp;{data.DesResult || ""}</div>}
            </>
        )
    };

    redirectScreen = (mode, e = "") => {
        const state = this.getInfo();
        const { iPermission, formData  } = this.state;
        const {VoucherDate, PerformancePeriod,PerformanceDateFrom, PerformanceDateTo,TransTypeID, StatusID, Note   } = formData;
        browserHistory.push({
            pathname: Config.getRootPath() + "W39F1030",
            state: {
                mode: mode,
                EvaluationVoucherID: e.data && e.data.EvaluationVoucherID,
                DepartmentID: e.data && e.data.DepartmentID,
                DepartmentName: e.data && e.data.DepartmentName,
                TransID: e.data && e.data.TransID,
                CreateDate: e.data && e.data.CreateDate,
                FormID: "W39F1025",
                StatusID: state.StatusID ? state.StatusID : StatusID ,
                TransTypeName: state.TransTypeName ? state.TransTypeName : "" ,
                iPermission: String(iPermission),
                TransTypeID: state.TransTypeID ? state.TransTypeID : TransTypeID,
                PerformanceDateFrom: state.PerformanceDateFrom ? state.PerformanceDateFrom : moment(PerformanceDateFrom).format("YYYY-MM-DD"),
                PerformancePeriod: state.PerformancePeriod ? state.PerformancePeriod : PerformancePeriod,
                PerformanceDateTo: state.PerformanceDateTo ? state.PerformanceDateTo : moment(PerformanceDateTo).format("YYYY-MM-DD") ,
                VoucherDate: state.VoucherDate ? state.VoucherDate : VoucherDate,
                Note: state.Note ? state.Note : Note
            }
        });
    };

    showPopupEdit = (e) => {
        const { data } = e.row;
        this.setState({
            rowData: data,
            showW39F1031Popup: true
        });
    };

    chooseRoom = () => {
        this.setState({
            showW09F6500Popup: true
        })
    };


    onChosen = (data) => {
        const {dataGrid} = this.state;
        const listDepartmentIDs = dataGrid.map(d => d.DepartmentID);
        const dataNew = data.filter(dn => listDepartmentIDs.indexOf(dn.DepartmentID) < 0);
        this.setState({
            dataGrid: dataGrid.concat(dataNew),
            showW09F6500Popup: false
        });
    };

    onDelete = (e) => {
        const { data} = e.row;

        this.setState((prevState) => ({
            dataGrid: prevState.dataGrid && prevState.dataGrid.filter(d => d.DepartmentID !== data.DepartmentID)
        }));
    };

    onShowPageDetail = (e) => {
        let mode = e.data.IsEvaluation === 0 ? "add" : "edit";
        this.redirectScreen(mode, e);
    };
    renderAction = (e) => {
        const { mode, StatusID, CreateUserID } = this.getInfo();
        const { data } = e.row;
        let checkKeyObject =  data.hasOwnProperty('TransID');
        const color = Number(data.IsDepartment) === 1 ? "#000000" : (Number(data.IsDepartment) === 3 ? "#FF3D71" : "#2B78E4");
        return (
            <div className={"display_row valign-bottom"}>

                {Number(data.IsDepartment) !== 0 && checkKeyObject === true && mode=== "edit" &&
                    <IconButton aria-label="view" className={"mgr5"} style={{color: color}} size="small" onClick={() => this.showPopupEdit(e)}>
                      <BorderColorIcon/>
                    </IconButton>
                }
                {checkKeyObject === true &&
                    <>
                <IconButton aria-label="view" className={"mgr5"} style={{color:"#FF9900"}} size="small" onClick={() => this.onShowPageDetail(e)}>
                    <i className={data.IsEvaluation === 0 ? "far fa-star" : "fas fa-star" } />
                </IconButton>

                </>
                }
                <IconButton  aria-label="delete" disabled={mode === "edit" ? (Config.profile.UserID !== CreateUserID) : !((Number(StatusID) !== 1) || (Number(data.IsRemove) === 1))} size="small" onClick={() => this.onDelete(e)}>
                    <i className="fas fa-trash-alt" />
                </IconButton>
            </div>
        );
    };

    onBack = () => {
        browserHistory.push(Config.getRootPath() + "W39F1020");
    };

    onCloseModal = () => {
        this.setState({
            showW39F1031Popup: false
        });
        this.loadDetail();
    };
    closePopup = () => {
        this.isRenderModalW09F6500 = false;
        this.setState({
            showW09F6500Popup: false
        })
    };

    render() {
        const { iPermission } = this.state;
        if (!this.getInfo(true)) return null;
        const { StatusID, mode, CreateUserID } = this.getInfo();
        const {
            getCboAppStatus,
            getEvaluationType,
        } = this.props;

        let { formData, modeEdit, dataGrid, loadingDataGrid, error, showW39F1031Popup, rowData, showW09F6500Popup, disableSave } = this.state;
        const { TransTypeID, VoucherDate, PerformancePeriod, PerformanceDateFrom, PerformanceDateTo , Note } = formData;
        return (
            <>

                <Modal
                open={showW39F1031Popup}
                maxWidth={"md"}
                fullWidth={true}
                onClose={this.onCloseModal}
                title={Config.lang("Cap_nhat_thong_tin_cho_phieu_danh_gia")}
            >
                <W39F1031Popup
                    iPermission = {iPermission}
                    statusID={StatusID}
                    data={rowData}
                    onClose={this.onCloseModal}
                />
            </Modal>

             <W09F6500 open={showW09F6500Popup}
                       mode={mode}
                       dataDepartment={dataGrid}
                       onChosen={this.onChosen}
                       onClose={this.closePopup} />

                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("Lap_phieu_danh_gia_phong_ban")}
                        onBack={this.onBack}
                    >
                        <ButtonCustom name={Config.lang('Chon_phong_ban')}
                                      color={"primary"}
                                      variant={"custom"}
                                      style={{ textTransform: 'uppercase' }}
                                      size={"large"}
                                      className={"mgr5"}
                                      disabled={(mode === "edit" ? (Config.profile.UserID !== CreateUserID) : disableSave) || (Number(StatusID) === 1)  || !(Number(iPermission) > 1)}
                                      onClick={this.chooseRoom} />
                        <ButtonGeneral name={Config.lang('Luu')}
                                       typeButton={'save'}
                                       disabled={mode === "edit" ? (Config.profile.UserID !== CreateUserID) :  (disableSave || (Number(StatusID) === 1) ||  !(Number(iPermission) > 1))}
                                       style={{ textTransform: 'uppercase' }}
                                       size={"large"}
                                       onClick={this.onSave} />
                    </ActionToolbar>
                </FormGroup>
                <div className={"pdt10 pdb10"}>
                    <Typography style={{fontSize: "15px", fontWeight:"bold"}}>
                        {Config.lang("Thong_tin_chung")}
                    </Typography>
                </div>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Combo
                            InputLabelProps={{
                                shrink: true
                            }}
                            error={error && error['TransTypeID']}
                            showClearButton={true}
                            dataSource={getEvaluationType}
                            margin={"normal"}
                            required={true}
                            selectProps={{readOnly: modeEdit}}
                            stylingMode={"underlined"}
                            label={Config.lang("Loai_danh_gia")}
                            displayExpr={"TransTypeName"}
                            valueExpr={"TransTypeID"}
                            onValueChanged={e => this.handleChange("TransTypeID", e)}
                            value={TransTypeID}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4}>
                        <DateBoxPicker
                            InputLabelProps={{
                                shrink: true
                            }}
                            required={true}
                            error={error && error['VoucherDate']}
                            label={Config.lang("Ngay_danh_gia")}
                            useMaskBehavior={true}
                            disabled={modeEdit}
                            showClearButton={true}
                            width={"100%"}
                            stylingMode={"underlined"}
                            margin={"normal"}
                            value={VoucherDate}
                            onValueChanged={e => this.handleChange("VoucherDate", e)}
                        />
                    </Col>
                    <Col xs={12} sm={8} md={8} lg={8}>
                        <Combo
                            InputLabelProps={{
                                shrink: true
                            }}
                            error={error && error['AppStatusID']}
                            dataSource={getCboAppStatus}
                            showClearButton={true}
                            margin={"normal"}
                            required={true}
                            selectProps={{readOnly: (Number(StatusID) === 1)}}
                            stylingMode={"underlined"}
                            label={Config.lang("Trang_thai_phieu_danh_gia")}
                            displayExpr={"AppStatusName"}
                            valueExpr={"AppStatusID"}
                            onValueChanged={e => this.handleChange("StatusID", e)}
                            value={Number(StatusID)}
                        />
                    </Col>
                </Row>
                    <FormControl variant={"standard"}
                                 margin={"normal"}
                                 fullWidth={true}>
                        <InputLabel
                            shrink={true}
                            required={true}
                        >
                            {Config.lang("So_thang")}
                        </InputLabel>
                        <Row>
                            <Col xs={12} sm={4} md={4} lg={4}>
                                <TextField
                                    error={error && error['PerformancePeriod']}
                                    variant={"standard"}
                                    InputLabelProps={{
                                        shrink: true
                                    }}

                                    inputProps={{readOnly: modeEdit}}
                                    value={ String(PerformancePeriod)}
                                    onChange={(e) => this.handleChange('PerformancePeriod', e)}
                                    margin={"normal"}
                                    multiline
                                    fullWidth
                                />
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4}>
                                <DateBoxPicker
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    max={PerformanceDateTo}
                                    required={true}
                                     // error={error && error['PerformanceDateFrom']}
                                    useMaskBehavior={true}
                                    showClearButton={true}
                                    width={"100%"}
                                    disabled={modeEdit}
                                    stylingMode={"underlined"}
                                    margin={"normal"}
                                    value={PerformanceDateFrom}
                                    onValueChanged={e => this.handleChange("PerformanceDateFrom", e)}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4}>
                                <DateBoxPicker
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    min={PerformanceDateFrom}
                                    required={true}
                                    // error={error && error['PerformanceDateTo']}
                                    useMaskBehavior={true}
                                    showClearButton={true}
                                    disabled={modeEdit}
                                    width={"100%"}
                                    stylingMode={"underlined"}
                                    margin={"normal"}
                                    value={PerformanceDateTo}
                                    onValueChanged={e => this.handleChange("PerformanceDateTo", e)}
                                />
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    label={Config.lang("Ghi_chu")}
                                    error={error && error['Note']}
                                    variant={"standard"}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{readOnly: (Number(StatusID) === 1)}}
                                    value={Note}
                                    onChange={(e) => this.handleChange('Note', e)}
                                    margin={"normal"}
                                    multiline
                                    fullWidth
                                />
                            </Col>
                        </Row>
                    </FormControl>
                <Row className={"pdt10 pdb10"}>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Typography style={{fontSize: "15px", fontWeight:"bold"}} >
                            {Config.lang("Danh_sach_phong_ban")}
                        </Typography>
                    </Col>
                </Row>
                <GridContainer
                    style={{ border: "none" }}
                    dataSource={dataGrid}
                    keyExpr={"DepartmentID"}
                    showRowLines={true}
                    showBorders={false}
                    typePaging={"normal"}
                    showColumnLines={false}
                    showColumnHeaders={false}
                    loading={loadingDataGrid}
                    hoverStateEnabled={true}
                    pagerFullScreen={false}
                    // height={Config.getHeightGrid()}
                    elementAttr={{
                        style: "min-height: 300px"
                    }}
                    onChangePage={this.onChangePage}
                    onChangePerPage={this.onChangePerPage}
                >
                    <Column
                        cellRender={this.renderItem}
                              minWidth={450}
                              />
                    <Column cellRender={this.renderAction} minWidth={400} />
                </GridContainer>
            </>
        )
    }
}

export default compose(
    connect(
        state => ({
             getCboAppStatus: state.general.getCboAppStatus,
            getEvaluationType: state.W39F1025.getEvaluationType,
            getDetail: state.W39F1025.getDetail,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W39F1020Actions: bindActionCreators(W39F1020Actions, dispatch),
            W39F1025Actions: bindActionCreators(W39F1025Actions, dispatch)
        })
    ),
    withStyles(styles, {withTheme: true})
)(W39F1025);