/**
 * @copyright 2020 @ DigiNet
 * @author CanhToan
 * @create 06/07/2020
 * @Example
 */
import React, {useMemo, useRef, useState, useEffect} from "react";
import { connect } from "react-redux";
import * as w39f1005Actions from "../../../../redux/W3X/W39F1005/W39F1005_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { Row, Col, FormGroup } from "react-bootstrap"
import UserImage from "../../../common/user/user-image";
import withStyles from "@material-ui/core/styles/withStyles";
import {MForm} from "../../../common/form-material";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import moment from "moment";
import W09F6000 from "../../../W0X/W09/W09F6000/W09F6000";
import { browserHistory } from "react-router";
import {
    ButtonIcon,
    Button,
    Dropdown,
    TextInput,
    NumberInput,
    DatePicker,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary
} from "diginet-core-ui/components";
import Icon from "diginet-core-ui/icons";
import _ from "lodash";

const arrStar = new Map([
    [0, 'StarOutline'],
    [0.5, 'StarHalf'],
    [1, 'Star'],
]);

const arrStatus = () => [
    {id: 0, name: Config.lang("Chua_danh_gia")},
    {id: 1, name: Config.lang("Dang_thuc_hien")},
    {id: 2, name: Config.lang("Hoan_thanh")}
];

const initCurrent = 50;

const styles = {
    iconButton: {
        width: 30,
        height: 30,
        position: "absolute",
        left: "1rem",
        top: 0
    },
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: "50%",
        overflow: "hidden",
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain"
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB"
    },
    dateInfo: {
        display: "flex",
        alignItems: "center"
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        color: 'blue' //"rgb(29, 76, 114)"
    },
    divIDText: {
        fontSize: "12px",
        fontWeight: 400,
    },
    divDateText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#000000"
    },
    justifyContentCenter: {
        display: 'flex',
        justifyContent: 'center!important',
        alignItems: 'center!important'
    },
    dataDetail: {
        height: '100%',
        maxHeight: 450,
        overflowY: 'auto'
    }
};
class W39F1005 extends React.PureComponent {
    constructor(props) {
        super(props);
        if (!this.getInfo()) return;
        this.state = {
            isPermission: {},
            isSaving: false,
            isSaved: false,
            isUpdated: false,
            disabled: false,
            showEmployeeSelect: false,
            evaluationDataDetails: [],
            evaluationDeleted: [],
            dataCboProjects: {
                rows: [],
                total: 0
            },
            dataCboTransType: [],
            TransTypeID: "",
            DutyID: [],
            ProjectID: "",
            PerformancePeriod: "",
            PerformanceDateTo: "",
            PerformanceDateFrom: "",
            StatusID: 0,
            Note: "",

            error: {
                TransTypeID: "",
                ProjectID: "",
                PerformancePeriod: "",
                PerformanceDateTo: "",
                PerformanceDateFrom: "",
                StatusID: ""
            },

            gridLoading: false,
            transTypeLoading: false,
            projectLoading: false,
            dataDefaults: {},
            current:initCurrent,
        };
        this.isRenderModalW09F6000 = false;
        this.filter = {
            limit: 10,
            skip: 0
        };
        this.filterCboProjects = {
            timer: null,
            skip: 0,
            limit: 20,
            strSearch: ""
        };
    }

    async loadPermission() {
        await this.props.generalActions.getPermission(["W39F1000", "W39F1006"], (arrPer) => {
            if (arrPer !== 0) {
                let isPermission = {};
                arrPer.forEach(per => {
                    if (per.hasOwnProperty('FormID') && per.hasOwnProperty('IsPermission')) {
                        isPermission[per.FormID] = per.IsPermission;
                    }
                });
                this.setState({isPermission: isPermission});
            }
        });
    };

    loadCboProjects = (isReset) => {
        const params = {
            HostID: "",
            FormID: "W39F1005",
            Language: Config.language || "84",
            skip: this.filterCboProjects.skip,
            limit: this.filterCboProjects.limit,
            search: this.filterCboProjects.strSearch
        };
        this.setState({projectLoading: true});
        this.props.generalActions.getCboProjects(params, (errors, data) => {
            this.setState({projectLoading: false});
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                const {dataCboProjects} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : _.concat(dataCboProjects.rows,rows),
                        total: total
                    }
                });
            }
        });
    };

    loadCboAppStatus = () => {
        const param = {
            FormID: "W39F1000",
            Language: Config.language || 84
        };

        this.props.generalActions.getCboAppStatus(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboTransType = () => {
        const params = {
            TranMonth: Config.getHRTransMonth(),
            TranYear: Config.getHRTransYear(),
            FormID: 'W39F1005',
        };
        this.setState({transTypeLoading: true});
        this.props.w39f1005Actions.loadCboTransType(params, (errors, data) => {
            this.setState({transTypeLoading: false});
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                this.setState({dataCboTransType: data})
            }
        });
    };

    loadMaster = () => {
        const {data} = this.getInfo();
        this.setState({
            ProjectID: data?.ProjectID || "",
            TransTypeID: data?.TransTypeID || "",
            DutyID: data.DutyID || [],
            PerformancePeriod: data.PerformancePeriod ? data.PerformancePeriod : '',
            PerformanceDateFrom: data.PerformanceDateFrom ? moment(data.PerformanceDateFrom, 'DDMMYYYY').format("YYYY-MM-DD") : '',
            PerformanceDateTo: data.PerformanceDateTo ? moment(data.PerformanceDateTo, 'DDMMYYYY').format("YYYY-MM-DD") : '',
            StatusID: +data.StatusID,
            Note: data.Note
        }, () => {
            this.loadDetail(data.EvaluationVoucherID);
        });
    };

    loadDetail = (EvaluationVoucherID) => {
        if (!EvaluationVoucherID) return;
        const params = {
            EvaluationVoucherID: EvaluationVoucherID
        };
        this.setState({ gridLoading: true });
        this.props.w39f1005Actions.loadDetail(params, (errors, dataDetail) => {
            this.setState({gridLoading: false});
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            this.setState({
                evaluationDataDetails: dataDetail || [],
            });
        });
    };


    getInfo = (flag) => {
        const { location } = this.props;
        if (location && location.state) {
            return {
                mode: this.state?.isSaved ? 'edit' : location.state.mode,
                data: location.state.data ? location.state.data : null
            }
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + "W39F1000");
                return null;
            }
            return false;
        }
    };

    componentDidMount = async () => {
        if (!this.getInfo()) return;
        await this.loadPermission();
        if (this.state.isPermission["W39F1000"] <= 0) return;
        const { mode, data } = this.getInfo();
        this.loadCboProjects();
        this.loadCboAppStatus();
        this.loadCboTransType();
        if (mode && mode !== 'add') {
            this.loadMaster();
            if (mode === "view" && data.AppStatusID !== 0) {
                this.setState({ disabled: true });
            }
        }
    };

    setStateErrorText = (value) => {
        const { error } = this.state;
        this.setState({
            error: {
                ...error,
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    };

    handleChange = (key, e) => {
        if (!key) return false;
        if (!this.state.isUpdated) {
            this.setState({isUpdated: true})
        }
        switch (key) {
            case "ProjectID":
                this.setState({ProjectID: e.value}, () => {
                   if(this.state.TransTypeID) this.loadSelectedEmployees(2);
                });
                break;
            case "TransTypeID": {
                this.setState({
                    TransTypeID: e.value,
                    DutyID: e.value.DutyID
                }, () => {
                    this.loadSelectedEmployees(2);
                });
                this.updateTransTime("TransTypeID", e?.data || null)
                break;
            }
            case "PerformancePeriod": {
                const value = e.target.value;
                if ((+value && value.includes('.') !== true) || value === '') {
                    if (+value < 10000) {
                        this.setState({
                            PerformancePeriod: value
                        }, () => {
                            // If value === '' then it will not updateTransTime
                            if (+value) {
                                setTimeout(() => {
                                    this.updateTransTime('PerformancePeriod', value);
                                }, 0);
                            }
                        })
                    } else {
                        Config.popup.show('INFO', Config.lang('So_thang_khong_duoc_qua_lon'));
                    }
                }
                break;
            }
            case "PerformanceDateFrom": {
                this.setState({
                    PerformanceDateFrom: e.value
                }, () => {
                    setTimeout(() => {
                        this.updateTransTime('PerformanceDateFrom', e.value);
                    }, 0);
                });
                break;
            }
            case "PerformanceDateTo": {
                this.setState({
                    PerformanceDateTo: e.value
                }, () => {
                    setTimeout(() => {
                        this.updateTransTime('PerformanceDateTo', e.value);
                    }, 0);
                });
                break;
            }
            case 'StatusID': {
                this.setState({
                    StatusID: e.value
                });
                break;
            }
            case "Note": {
                const value = e.target.value;
                setTimeout(() => {
                    this.setState({
                        Note: value
                    })
                }, 0);
                break;
            }
            default:
                break;
        }
    };

    updateTransTime = (key, data = {}) => {
        if (data) {
            let { PerformancePeriod, PerformanceDateFrom, PerformanceDateTo } = this.state;
            switch (key) {
                case 'PerformancePeriod': {
                    if (PerformanceDateFrom) {
                        // Update PerformanceDateTo: To = From + NumMonths + 1 (day)
                        PerformanceDateTo = moment(PerformanceDateFrom).add(data, 'months').subtract(1, 'day').format('YYYY-MM-DD');
                        this.setState({
                            PerformanceDateTo
                        });
                    } else if (PerformanceDateTo) {
                        // Update PerformanceDateFrom: From = To - NumMonths + 1 (day)
                        PerformanceDateFrom = moment(PerformanceDateTo).subtract(data, 'months').add(1, 'day').format('YYYY-MM-DD');
                        this.setState({
                            PerformanceDateFrom
                        });
                    }
                    break;
                }
                case 'PerformanceDateFrom':
                    if (PerformancePeriod) {
                        // Update PerformanceDateTo: To = From + NumMonths + 1 (day)
                        PerformanceDateTo = moment(data).add(PerformancePeriod, 'months').subtract(1, 'day').format('YYYY-MM-DD');
                        this.setState({
                            PerformanceDateTo
                        });
                    } else if (PerformanceDateTo) {
                        // Update PerformancePeriod: NumMonths = [To + 1 (day)] - From
                        PerformancePeriod = moment(PerformanceDateTo).add(1, 'day').diff(data, 'months');
                        this.setState({
                            PerformancePeriod
                        });
                    }
                    break;
                case 'PerformanceDateTo': {
                    PerformanceDateTo = moment(data);
                    if (PerformanceDateFrom) {
                        // Update PerformancePeriod: NumMonths = [To + 1 (day)] - From
                        PerformancePeriod = PerformanceDateTo.add(1, 'day').diff(PerformanceDateFrom, 'months');
                        this.setState({
                            PerformancePeriod
                        });
                    } else if (PerformancePeriod) {
                        // Update PerformanceDateFrom: From = To - NumMonths + 1 (day)
                        PerformanceDateFrom = PerformanceDateTo.subtract(PerformancePeriod, 'months').add(1, 'day').format('YYYY-MM-DD');
                        this.setState({
                            PerformanceDateFrom
                        });
                    }
                  break;
                }
                default: {
                    this.setState({
                        PerformancePeriod: data.PerformancePeriod || '',
                        PerformanceDateTo: moment(data.PerformanceDateTo).format('YYYY-MM-DD') || '',
                        PerformanceDateFrom: moment(data.PerformanceDateFrom).format('YYYY-MM-DD') || ''
                    });
                    break;
                }
            }
        }
    };

    openEmployeeSelect = () => {
        const {DutyID, ProjectID, evaluationDataDetails} = this.state;
        const dataSelected = evaluationDataDetails.map(rs => {
            const user = Config.getUser({EmployeeID: rs.EmployeeID});
            return {...rs, ...user}
        });
        this.setState({
            dataDefaults: {
                DutyID: DutyID,
                ProjectID: !!ProjectID ? [ProjectID] : "",
                dataSelected: dataSelected,
                selectedRowKeys: evaluationDataDetails.map(e => e.EmployeeID),
                isLoaded: true
            },
            showEmployeeSelect: true
        });
    };

    closeEmployeeSelect = () => {
        this.setState({ showEmployeeSelect: false, dataDefaults: null });
    };

    onSelectedEmployee = (EmployeeIDs, EmployeesData) => {
        const { evaluationDataDetails, evaluationDeleted } = this.state;
        const dataTemp = [...evaluationDataDetails];
        const disabledEmployee = dataTemp.filter(i=>!!i.IsMultiLevel && !!i.IsEvaluation);
        EmployeesData = EmployeesData.map(i=>{

            // BA yêu cầu mặc định IsAppStatus:0, IsEvaluation: 0 sau khi chọn Employee mới
            let item = {...i, IsAppStatus:0, IsEvaluation: 0};

            // check item is exist
            const exits = evaluationDataDetails.find(j=>j.EmployeeID === i.EmployeeID);

            // if item is exist and isNew then keep isNew
            if(exits?.IsNew) item.IsNew = exits?.IsNew;

            // if item is doesn't exist then set isNew
            if(!exits){
                item.IsNew = 1;
                delete item.DepartmentName;
            }
            return item;
        });
        // Không được xoá Employee có "button Delete is disabled"
        disabledEmployee.forEach(i=>{
            const idx = EmployeesData.findIndex(j=>j.EmployeeID === i.EmployeeID);
            if(idx === -1){
                EmployeesData.push(i);
            }
            else{
                EmployeesData[idx]=i;
            }
        });
        const evaluationDeletedTemp = [...evaluationDeleted];
        dataTemp.forEach(i=>{
            if(!EmployeesData.find(j=>j.EmployeeID === i.EmployeeID)){
                evaluationDeletedTemp.push(i);
            }
        });

        this.setState({evaluationDataDetails: EmployeesData, evaluationDeleted: evaluationDeletedTemp, isUpdated: true, gridLoading: true},()=>{
            return this.closeEmployeeSelect();
        });
        this.closeEmployeeSelect();
    };

    loadSelectedEmployees = (mode) => {
        const {TransTypeID, ProjectID} = this.state;
        const params = {
            EvaluationVoucherID: "",
            FormID: "W39F1000",
            Mode: mode,
            TransTypeID,
            ProjectID
        };
        this.setState({gridLoading: true});
        this.props.w39f1005Actions.loadDetail(params, (errors, data) => {
            this.setState({gridLoading: false});
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                const selectedEmployees = [...this.state.evaluationDataDetails];
                const listEmployees = selectedEmployees.map((e) => {
                    return e.EmployeeID;
                });
                if(mode === 1) {
                    if(data && data.length > 0) {
                        data.forEach((e) => {
                            if (listEmployees.indexOf(e.EmployeeID) < 0) {
                                e.IsNew = 1;
                                selectedEmployees.unshift(e);
                            }
                        });
                    }
                }
                if(mode === 2) {
                    if(data && data.length > 0) {
                        data.forEach((e) => {
                            e.IsNew = 1;
                        });
                    }

                }
                this.setState({
                    evaluationDataDetails: mode === 2 ? data : selectedEmployees
                });
            }
        });
    };

    onSave = () => {
        const {mode} = this.getInfo();
        const {TransTypeID, StatusID, PerformancePeriod, PerformanceDateFrom} = this.state;

        //validate form...
        const validateRules = [
            {
                name: "StatusID",
                rules: "isRequired",
                value: StatusID
            }
        ];
        if (mode === 'add') {
            validateRules.push(...[
                {
                    name: "TransTypeID",
                    rules: "isRequired",
                    value: TransTypeID
                },
                {
                    name: "PerformancePeriod",
                    rules: "isRequired",
                    value: PerformancePeriod
                },
                {
                    name: "PerformanceDateFrom",
                    rules: "isRequired",
                    value: PerformanceDateFrom
                }
            ]);
        }
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length !== 0) { //Neu co loi validate
            this.setStateErrorText(validateForm);
            return false;
        } else {
            Config.popup.show('YES_NO', Config.lang("Ban_co_chac_muon_luu_khong?"), () => {
                this._saveData();
            });
        }
    };

    _saveData = () => {
        //const dataSource = data ? data : this.dataGrid.instance.option('dataSource');
        const {
            TransTypeID,
            ProjectID,
            evaluationDeleted,
            evaluationDataDetails,
            PerformancePeriod,
            PerformanceDateTo,
            PerformanceDateFrom,
            StatusID,
            Note
        } = this.state;
        const {mode, data} = this.getInfo();
        this.setState({isSaving: true, loading: true}); //set status..

        const dataEmployee = [];
        evaluationDataDetails.forEach(e => {
            if (e.IsEvaluation === 0 && e.IsNew) {
                dataEmployee.push({
                    EmployeeID: e.EmployeeID,
                    IsEvaluation: e.IsEvaluation,
                    IsAppStatus: e.IsAppStatus,
                    TransID: e.TransID,
                    VoucherDate: e.VoucherDate,
                    Deleted: e.Deleted || 0,
                });
            }
        });

        if (evaluationDeleted.length) {
            evaluationDeleted.forEach(e => {
                dataEmployee.push({
                    ...e,
                    Deleted: 1
                })
            })
        }

        const params = {
            FormID: "W39F1005",
            TranMonth: Config.getHRTransMonth(),
            TranYear: Config.getHRTransYear(),
            DivisionID: Config.getDivisionID(),
            NoteU: Note || "",
            TransTypeID,
            PerformancePeriod: PerformancePeriod || 0,
            PerformanceDateFrom: PerformanceDateFrom || '',
            PerformanceDateTo: PerformanceDateTo || '',
            ProjectID,
            StatusID: StatusID || 0,
            Language: Config.language || "84",
            data: JSON.stringify(dataEmployee)
        };
        if (mode === 'edit') {
            params["EvaluationVoucherID"] = data?.EvaluationVoucherID || this.state.isSaved || "";
        }
        this.props.w39f1005Actions[mode](params, (errors, dataDetail) => {
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({ isSaving: false, loading: false });
                return false;
            }
            if (dataDetail) {
                const { isSaved } = this.state;
                const EvaluationVoucherID = isSaved ? isSaved : mode === 'add' ? dataDetail.EvaluationVoucherID : data.EvaluationVoucherID;
                this.setState({
                    isSaving: false,
                    loading: false,
                    disabled: true,
                    isSaved: EvaluationVoucherID,
                    isUpdated: false,
                    gridLoading: true,
                    evaluationDeleted: []
                });
                Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                // ReLoadDetal

                const dataT = params;
                dataT.PerformanceDateFrom = moment(dataT.PerformanceDateFrom, 'YYYY-MM-DD').format("DD/MM/YYYY");
                dataT.PerformanceDateTo = moment(dataT.PerformanceDateTo, 'YYYY-MM-DD').format("DD/MM/YYYY");
                dataT.EvaluationVoucherID = EvaluationVoucherID;
                if(dataT.NoteU) dataT.Note = dataT.NoteU;

                browserHistory.replace({
                    pathname: Config.getRootPath() + "W39F1005",
                    state: {
                        mode: "edit",
                        data: dataT,
                    }
                });

                this.loadDetail(EvaluationVoucherID);

            } else {
                this.setState({ isSaving: false });
            }
        });
    };

    onBack = () => {
        browserHistory.push(Config.getRootPath() + "W39F1000");
    };

    onDelete = ({index, EmployeeID}) => {
        Config.popup.show('YES_NO', Config.lang("Ban_co_chac_muon_xoa?"), () => {
            this.props.w39f1005Actions.removeItem(index, error => {
                if (error) {
                    Config.popup.show('INFO', Config.lang("Loi_chua_xac_dinh"));
                }
                // Remove EmployeeID out EmployeeIDs state
                const { evaluationDeleted, evaluationDataDetails } = this.state;

                const newEvaluationDeleted = evaluationDeleted.concat(evaluationDataDetails.filter(e => !e.IsNew && e.EmployeeID === EmployeeID));

                this.setState({
                    evaluationDeleted: newEvaluationDeleted,
                    evaluationDataDetails: evaluationDataDetails.filter(e => e.EmployeeID !== EmployeeID),
                    isUpdated: true
                });
            });
        });
    };

    onDetail = e => {
        const { mode, data } = this.getInfo();
        const {isPermission} = this.state;
        if(isPermission["W39F1000"] < 4 || !!data?.IsNew) return;

        browserHistory.push({
            pathname: Config.getRootPath() + "W39F1011",
            state: {
                EmployeeID: e.EmployeeID,
                TransID: e.TransID,
                EvaluationVoucherID: e.EvaluationVoucherID,
                FormID: "W39F1005",
                FormPermission: "W39F1000",
                IsSave: e.IsSave,
                UserLevel: e.UserLevel,
                ApproveLevel: e.ApproveLevel,
                Mode: 0,
                mode: mode,
                data: data
            }
        });
    };

    /** Action when click icon Lock or Unlock
     * @param data {object}: data one row detail
     */
    onLock = (data) => {
        const params = {
            IsLocked: +!data.IsLocked,
            EvaluationVoucherID: data.EvaluationVoucherID,
            TransTypeID: data.TransTypeID,
            TransID: data.TransID,
            EmployeeID: data.EmployeeID,
        };

        // call api update status lock
        this.props.w39f1005Actions.updateLock(params, (err, dataUpdate) => {
            if (err) {
                let message = err.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (dataUpdate?.Status === 1) {
                const msg = dataUpdate.Message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('ERROR', msg);
                return false;
            } else {
                this.loadDetail(data.EvaluationVoucherID);
                Config.notify.show('success', Config.lang("Cap_nhat_thanh_cong"), 2000);
            }
        });
    };

    render() {
        if (!this.getInfo(true)) return null;
        const { mode, data } = this.getInfo();
        const { evaluationDataDetails, disabled, loading, error, showEmployeeSelect, isSaving, isUpdated,
            dataCboProjects, dataCboTransType, transTypeLoading, projectLoading,
            PerformancePeriod, PerformanceDateTo, PerformanceDateFrom, StatusID, dataDefaults, current,
            isPermission, TransTypeID, ProjectID } = this.state;
        return (
            <React.Fragment>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("Lap_phieu_danh_gia_nhan_vien")}
                        onBack={this.onBack}
                    >
                        <div style={{display: "flex", marginLeft: "auto"}}>
                            <Button text={Config.lang('Chon_nhan_vien')}
                                    startIcon={"AddPerson"}
                                    className={"mgr5"}
                                    viewType={"outlined"}
                                    size={"medium"}
                                    color={"primary"}
                                    disabled={isSaving || (mode !== 'add' && StatusID !== 0)}
                                    onClick={this.openEmployeeSelect}
                            />
                            <Button text={Config.lang('Luu')}
                                    startIcon={'save'}
                                    viewType={"filled"}
                                    loading={isSaving}
                                    disabled={mode === 'view' || !isUpdated}
                                    color={"info"}
                                    size={"medium"}
                                    onClick={this.onSave}/>
                        </div>
                    </ActionToolbar>
                </FormGroup>
                {showEmployeeSelect &&
                    <W09F6000 open={showEmployeeSelect}
                              dataDefaults={dataDefaults}
                              FormID={"W39F1000"}
                              onChosen={this.onSelectedEmployee}
                              onClose={this.closeEmployeeSelect}
                    />
                }
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <Dropdown
                                            required
                                            allowSearch
                                            dataSource={dataCboTransType}
                                            error={error && error['TransTypeID']}
                                            loading={transTypeLoading}
                                            displayExpr={'TransTypeName'}
                                            keyExpr={'TransTypeName'}
                                            valueExpr={'TransTypeID'}
                                            disabled={loading || disabled || mode === 'edit'}
                                            value={TransTypeID}
                                            label={Config.lang('Loai_danh_gia')}
                                            placeholder={Config.lang('Chon')}
                                            onChange={e => this.handleChange("TransTypeID", e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <Dropdown
                                            allowSearch
                                            clearAble
                                            error={error && error['ProjectID']}
                                            valueObjectDefault={mode !== "add" ? {
                                                ProjectID: data?.ProjectID ?? "",
                                                ProjectName: data?.ProjectName ?? ""
                                            } : null}
                                            dataSource={dataCboProjects.rows}
                                            total={dataCboProjects.total}
                                            skip={this.filterCboProjects.skip}
                                            limit={this.filterCboProjects.limit}
                                            displayExpr={'{ProjectID} - {ProjectName}'}
                                            keyExpr={'ProjectName'}
                                            valueExpr={'ProjectID'}
                                            value={ProjectID}
                                            disabled={loading || disabled || mode === 'edit'}
                                            loading={projectLoading}
                                            label={Config.lang('Du_an')}
                                            placeholder={Config.lang('Chon')}
                                            onChange={e => this.handleChange("ProjectID", e)}
                                            onInput={(e) => {
                                                if (this.filterCboProjects.timer) clearTimeout(this.filterCboProjects.timer);
                                                this.filterCboProjects.strSearch = e.target.value;
                                                this.filterCboProjects.timer = setTimeout(() => {
                                                    this.filterCboProjects.skip = 0;
                                                    this.filterCboProjects.limit = 20;
                                                    this.loadCboProjects(true);
                                                }, 300);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboProjects.skip = e.skip;
                                                this.filterCboProjects.limit = e.limit;
                                                this.loadCboProjects();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <Row>
                                            <Col xs={2}>
                                                <NumberInput
                                                    error={error && error["PerformancePeriod"] && 'Bắt buộc nhập'}
                                                    label={Config.lang("So_thang")}
                                                    disabled={loading || disabled || mode === 'edit'}
                                                    value={String(PerformancePeriod)}
                                                    required
                                                    min={1}
                                                    onChange={(e) => this.handleChange('PerformancePeriod', e)}
                                                />
                                            </Col>
                                            <Col xs={5}>
                                                <div style={{paddingBottom: 21}}/>
                                                <DatePicker
                                                    actionIconAt="start"
                                                    error={error && error['PerformanceDateFrom']}
                                                    disabled={loading || disabled || mode === 'edit'}
                                                    max={PerformanceDateTo}
                                                    value={PerformanceDateFrom}
                                                    displayFormat={"DD/MM/YYYY"}
                                                    placeholder={"DD/MM/YYYY"}
                                                    onChange={e => this.handleChange('PerformanceDateFrom', e)}
                                                />
                                            </Col>
                                            <Col  xs={5}>
                                                <div style={{paddingBottom: 21}}/>
                                                <DatePicker
                                                    actionIconAt="start"
                                                    error={error && error['PerformanceDateTo']}
                                                    min={PerformanceDateFrom}
                                                    value={PerformanceDateTo}
                                                    displayFormat={"DD/MM/YYYY"}
                                                    placeholder={"DD/MM/YYYY"}
                                                    disabled={loading || disabled || mode === 'edit'}
                                                    onChange={e => this.handleChange('PerformanceDateTo', e)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <Dropdown
                                            error={error && error['StatusID']}
                                            dataSource={this.props.getCboAppStatus}
                                            displayExpr={'AppStatusName'}
                                            valueExpr={'AppStatusID'}
                                            required
                                            disabled={loading || mode === 'view'}
                                            value={StatusID}
                                            label={Config.lang('Trang_thai_phieu_danh_gia')}
                                            onChange={(e) => this.handleChange('StatusID', e)}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            label={Config.lang('Ghi_chu')}
                                            value={data?.Note}
                                            disabled={loading || mode === 'view'}
                                            onChange={e => this.handleChange('Note', e)}
                                            fullWidth
                                            multiline
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Accordion expand={evaluationDataDetails.length > 0}>
                        <ListUser
                            evaluationDataDetails={evaluationDataDetails}
                            current={current}
                            isPermission={isPermission}
                            getInfo= {this.getInfo}
                            onDelete= {this.onDelete}
                            onLock= {this.onLock}
                            onDetail= {this.onDetail}
                            classes={this.classes}
                            generalActions={this.props.generalActions}
                        />
                    </Accordion>
                </FormGroup>
            </React.Fragment>
        )
    }
}

const ListUser = React.memo((props)=>{
    const {
        evaluationDataDetails = [],
        getInfo,
        onDelete,
        onLock,
        onDetail,
        isPermission,
        generalActions
    } = props;

    const filterCboEmployees = useRef({
        strSearch: '',
        skip: 0,
        limit: 50
    });

    const filterListUser = useRef({
        Status: null,
        EmployeeID: '',
    });

    const isLoadGrid = useRef(false);

    const [loadingCboEmployees, setLoadingCboEmployees] = useState(false);
    const [dataCboEmployees, setDataCboEmployees] = useState({rows:[]});
    const [current, setCurrent] = useState(initCurrent);

    useEffect(()=>{
        loadCboEmployees();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    const loadCboEmployees = (isReset) => {
        const { skip, limit, strSearch } = filterCboEmployees.current;
        const params = {
            FormID: "W39F1005",
            Language: Config.language || '84',
            skip: skip,
            limit: limit,
            search: strSearch ? strSearch : ''
        };
        setLoadingCboEmployees(true);
        generalActions.getCboEmployees(params, (error, data) => {
            setLoadingCboEmployees(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const rows = data?.rows || [];
                const total = data?.total;
                setDataCboEmployees({
                    rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                    total: total
                })
            }
        });
    };

    /**
     * loadMore data when scroll
     * @param e
     */
    const handleScroll = (e) => {
        const {evaluationDataDetails} = props;
        if (isLoadGrid.current || current >= evaluationDataDetails?.length) return;

        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const bottom = scrollTop && ((scrollHeight - scrollTop) <= clientHeight + 150);

        if (bottom) {
            isLoadGrid.current = true;
            setCurrent(current+30);
        }
    };

    const listUser = useMemo(()=>{
        return [...evaluationDataDetails]?.filter(i => {
            return (filterListUser.current?.EmployeeID ? i.EmployeeID === filterListUser.current?.EmployeeID : true) && ((filterListUser.current?.Status || filterListUser.current?.Status === 0) ? i.IsEvaluation === filterListUser.current?.Status : true);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[evaluationDataDetails, current]);

    return(
        <>
            <AccordionSummary>
                {Config.lang("Danh_sach_nhan_vien_danh_gia")} ({listUser.length ?? 0}/{ evaluationDataDetails.length ?? 0})
            </AccordionSummary>
            <AccordionDetails>
                <Row className={'display_row mgt15 align-center'}>
                    <Col xs={3} sm={3} md={3} lg={12}>
                        <Dropdown
                            clearAble
                            searchDelayTime={1000}
                            className={'mgb0'}
                            valueExpr={"EmployeeID"}
                            keyExpr={"EmployeeName"}
                            label={Config.lang("Nhan_vien")}
                            placeholder={Config.lang("Chon")}
                            displayExpr={"{EmployeeID} - {EmployeeName}"}
                            onChange={(e) => {
                                filterListUser.current['EmployeeID'] = e?.value || null;
                            }}
                            dataSource={_.get(dataCboEmployees, "rows", [])}
                            total={_.get(dataCboEmployees, "total", 0)}
                            skip={filterCboEmployees.current.skip}
                            limit={filterCboEmployees.current.limit}
                            loading={loadingCboEmployees}
                            onInput={(e) => {
                                filterCboEmployees.current.strSearch = e.target.value;
                                filterCboEmployees.current.skip = 0;
                                loadCboEmployees(true);
                            }}
                            onLoadMore={(e) => {
                                filterCboEmployees.current.skip = e.skip;
                                filterCboEmployees.current.limit = e.limit;
                                loadCboEmployees();
                            }}
                        />
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={12}>
                        <Dropdown
                            clearAble
                            dataSource={arrStatus()}
                            className={'mgb0'}
                            valueExpr={"id"}
                            displayExpr={"name"}
                            label={Config.lang("Trang_thai")}
                            placeholder={Config.lang("Chon")}
                            onChange={(e) => {
                                filterListUser.current['Status'] = e?.value ?? null;
                            }}
                        />
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={12}>
                        <Button startIcon={'Search'}
                                color={'primary'}
                                viewType={'outlined'}
                                onClick={()=>{
                                    isLoadGrid.current = true;
                                    setCurrent(current === initCurrent ? current+1 : initCurrent);
                                }}>
                            {Config.lang("DHR_Tim_kiem")}
                        </Button>
                    </Col>
                </Row>
                <div className={"display_row wrap"}
                     style={{
                         height: '100%',
                         maxHeight: 450,
                         overflowY: 'auto'
                     }}
                     onScroll={handleScroll}
                >
                    {listUser?.map((dataDetail, index) => {
                        if (index >= current) return null;
                        return (
                            <Col xs={12} sm={12} md={6} lg={6} key={index}>
                                <Row className={'display_row align-center valign-middle'}>
                                    <EmpProfile data={dataDetail} onDetail={onDetail}/>
                                    <Action data={dataDetail}
                                            getInfo={getInfo}
                                            onDelete={onDelete}
                                            onLock={onLock}
                                            onDetail={onDetail}
                                            isPermission={isPermission}
                                    />
                                </Row>
                            </Col>
                        )
                    })}
                    {!listUser.length && <Typography type={'p1'} className={'full_w pdt15 display_row align-center'}>{Config.lang("Khong_co_du_lieu")}</Typography>}
                </div>
            </AccordionDetails>
        </>
    )

});

const EmpProfile = React.memo(props => {
    const { data, onDetail } = props;
    const user = Config.getUser({EmployeeID: data.EmployeeID});
    return (
        <Col className={"display_row align-center pdt10 pdb10"} xs={5} sm={5} md={6} lg={5}>
            <UserImage user={user} onClick={onDetail}/>
            <div>
                <Typography type={'h3'}
                            color={"info"}
                            onClick={onDetail}
                >
                    {user.EmployeeName}
                </Typography>
                <Typography type={'p2'}>{data.EmployeeID ?? ''}</Typography>
                <Typography type={'h3'}>{data.DepartmentName ?? ""}</Typography>
                <div className={'display_row align-center'}>
                    <Icon name={'CalendarBlankRounded'} width={18} className={'mgr5'}/>
                    <Typography type={'p2'} color={Config.coreTheme?.colors?.placeholder}>
                        {data.VoucherDate ?? ''}
                    </Typography>
                </div>
            </div>
        </Col>
    );
});

const Action = React.memo(props => {
    const { data, index, getInfo, onDelete, onLock, onDetail, isPermission } = props;
    const { AppStatusID } = getInfo()?.data || {};
    const { colors:{info, disabled} } = Config.coreTheme;

    const colorStarIcon = data.IsNew ? Config.coreTheme?.colors?.disabled : 'warning';

    return (
        <Col className={"display_row align-center valign-middle"}
             xs={5} sm={4} md={6} lg={5}
        >
            <ButtonIcon disabled={isPermission["W39F1000"] < 4 || !!data.IsNew}
                        size="small"
                        viewType={"text"}
                        circular={true}
                        color={colorStarIcon}
                        className={'mgr5'}
                        name={arrStar.get(Number(data?.IsEvaluation)/2 ?? 0)}
                        onClick={() => onDetail(data)}
            />

            {Number(data.IsApproved) === 1 &&
                <Icon name={"Approval"} color={data.IsAppStatus ? info : disabled} className={'mgr5'}/>
            }

            <ButtonIcon size="small"
                        circular={true}
                        viewType={"text"}
                        name={"Delete"}
                        className={'mgr5'}
                        disabled={!!data.IsMultiLevel && !!data.IsEvaluation}
                        onClick={() => onDelete({index: index, EmployeeID: data.EmployeeID})}
            />

            {Number(AppStatusID)  === 1 && isPermission["W39F1006"] > 0 &&
                <ButtonIcon name={Number(data?.IsLocked) === 0 ? "Unlock" : "Lock"}
                            size={"small"}
                            circular={true}
                            viewType={"text"}
                            onClick={()=>onLock(data)}
                />
            }
        </Col>
    );
});

export default compose(connect((state) => ({
    getCboAppStatus: state.general.getCboAppStatus,
    dataDetail: state.W39F1005.dataDetail,
}), (dispatch) => ({
    w39f1005Actions: bindActionCreators(w39f1005Actions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
})), withStyles(styles))(W39F1005);
