/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 7/7/2020
 * @Example
 */

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as W39F1031Actions from "../../../../redux/W3X/W39F1031/W39F1031_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { Row, Col, FormGroup } from "react-bootstrap";
import ButtonGeneral from "../../../common/button/button-general";
import withStyles from "@material-ui/core/styles/withStyles";
import * as generalActions from "../../../../redux/general/general_actions";
import Attachments from "../../../common/attachments/attachments";
import IconButton from "@material-ui/core/IconButton";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Modal from "../../../common/modal/modal";
import { OutlinedInput, Typography } from "@material-ui/core";
const styles = (theme) => ({
    caption: {
        paddingTop: "7%",
        [theme.breakpoints.down("md")]: {
            paddingTop: 0,
        },
    }
})
class W39F1031Popup extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showW39F1031Popup: false,
            error: {},
            loading: false,
            uploading: false,
            dataOldAttachments: [],
            RefEvaluation01U : "",
        };
        this.attachments = [];
        this.deletedFile = [];
    }

    componentDidMount() {
        const { data } = this.props;
    this.loadAttachments();
       this.setState({RefEvaluation01U : data.RefEvaluation01U});
    }

    resetForm = () => {
        this.setState({
        });
    };

    onClose = () => {
        const { onClose } = this.props;
        this.resetForm();
        if (onClose) onClose();
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    onUploading = value => {
        this.setState({ uploading: value });
    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;

        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };


     loadAttachments = () => {
            const { data } = this.props;
            const param = {
                KeyID: data.TransID,
                TableName: "D39T2002"
            };
            this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
                if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
                if (data && data.length > 0) {
                    this.setState({
                        dataOldAttachments: data,
                        loading:false,
                    });
                }
                this.setState({
                    loading:false,
                });
            });
        };

    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [ ...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            }));
        }
    };
    onClose = () => {
        const { onClose } = this.props;
        if (onClose) onClose();
    };

    onSave = () => {
        const { data } = this.props;
        const { RefEvaluation01U } = this.state;
        const arrAttachments = this._getAttachments();
        const params = {
            TransID: data.TransID,
            RefEvaluation01U: RefEvaluation01U,
            DepartmentID: data.DepartmentID,
            arrAttachment:JSON.stringify(arrAttachments)
        }

        this.props.W39F1031Actions.save(params, errors => {
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            this.onClose();
            Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 2000);
        })
    }

    handleChange = (key, e) => {
        if (!key) return false;
        switch (key) {
            case "RefEvaluation01U":
                this.setState({RefEvaluation01U: e.target.value});
                break;
            default:
                break;
        }
    }

    render() {
        let {
            statusID,
            iPermission,
            classes
        } = this.props;
        const {
            uploading,
            loading,
            dataOldAttachments,
            disabled,
            RefEvaluation01U
        } = this.state;
        return (
            <>
                <Modal.Content>
                    <FormGroup>
                        <Row>
                            <Col md={3} xs={12}>
                                <div className={classes.caption}>
                                    <Typography align="left" component="h6">
                                        {Config.lang('DHR_Ghi_chu')}
                                    </Typography>
                                </div>
                            </Col>
                            <Col md={9} xs={12}>
                                <OutlinedInput
                                    fullWidth={true}
                                    value={RefEvaluation01U}
                                    onChange={(e) => this.handleChange("RefEvaluation01U",e)}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        "aria-label": "weight",
                                    }}
                                    multiline={true}
                                    labelWidth={0}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup style={{ marginBottom: 34 }}>
                        <Attachments
                            isAttachInfo
                            ref={ref => this.attRef = ref}
                            showButton={true}
                            files={dataOldAttachments}
                            disabled={(Number(statusID) === 1 ||  dataOldAttachments.IsAttached === 0 ? true : false)}
                            maxLength={5}
                            // uploading={loading}
                            onUploading={this.onUploading}
                            onChanged={this.onChangeAttachments}
                        />
                    </FormGroup>
                </Modal.Content>
                <Modal.Actions style={{ justifyContent: "space-between" }}>
                    <div>
                        <IconButton
                            aria-label="view"
                            disabled={loading || disabled || uploading}
                            onClick={this.onAttachment}
                        >
                            <AttachFileIcon />
                        </IconButton>
                    </div>
                    <div>
                        <ButtonGeneral
                            name={Config.lang("DHR_Luu")}
                            typeButton={"save"}
                            className={Number(statusID) === 1 ? "hidden": ""}
                            disabled={(!iPermission > 1)}
                            style={{ textTransform: "uppercase" }}
                            size={"large"}
                            onClick={this.onSave}
                        />
                    </div>
                </Modal.Actions>
            </>
        );
    }
}

W39F1031Popup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default compose(
    connect(
        state => ({
            getAttachmentsByTransID: state.general.getAttachmentsByTransID,
            getLoadListAttachments: state.general.getLoadListAttachments
        }),
        dispatch => ({
            W39F1031Actions: bindActionCreators(W39F1031Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch)
        })
    ),
    withStyles(styles)
)(W39F1031Popup);
