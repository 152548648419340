/***********************************************************************/
/*                                                                     */
/*   Copyright (C) 2020. All rights reserved                           */
/*   License    : https://diginet.com.vn                               */
/*   Author     : RocaChien                                            */
/*                                                                     */
/*   Created    : 09-07-2020 10:13:05.                                 */
/*   Modified   : 09-07-2020 10:13:05.                                 */
/*                                                                     */
/***********************************************************************/

import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import _ from "lodash";
import validator from 'validator';

import { bindActionCreators, compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {LoadPanel} from "devextreme-react";

import Config from "../../../../config";
import { Image} from "react-bootstrap";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import {TextField} from "../../../common/form-material";
import CDN from "../../../CDN";
import Attachments from "../../../common/attachments/attachments";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";

import * as W39F1011Actions from "../../../../redux/W3X/W39F1011/W39F1011_actions";
import * as generalActions  from "../../../../redux/general/general_actions";
import * as mainActions     from "../../../../redux/main/main_actions";
import moment               from "moment";
// import jsPDF                from "jspdf";
import html2canvas                                                                            from "html2canvas";
import NumberFormat                                                                           from "react-number-format";
import Icon                                                                                   from 'diginet-core-ui/icons';
import {Button, ButtonIcon, FormGroup, Tooltip, TextInput,
    Dropdown,Row,Col, Popover, Typography}  from 'diginet-core-ui/components';
import {FormHelperText ,Container , FormControl, Grid, Chip ,InputAdornment} from "@material-ui/core";
import GridContainer from "../../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";

/************************************/
/* GLOBAL VARIABLE OR FUNCTIONS     */
/************************************/
const convertToRoman = (num) => {
    const roman = {M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1};
    let str = '';

    for (let i of Object.keys(roman)) {
        const q = Math.floor(num / roman[i]);
        num -= q * roman[i];
        str += i.repeat(q);
    }

    return str;
};
const styles = theme => {
    return {
        dateInfo: {
            display: 'flex',
            alignItems: 'center',
        },
        divText: {
            // fontSize: "14px",
            fontWeight: 500,
            // color: '#575757',
        },
        divDateText: {
            fontSize: "14px",
            fontWeight: 400,
            color: '#000000',
        },
        fieldset: {
            display: "flex",
            marginTop: "10px"
        },
        validateField: {
            '&:not(.disableDot)': {
                '& .MuiInputBase-root': {
                    '&:before': {
                        borderBottom: '1px solid rgb(255, 61, 113) !important'
                    },
                    '&:after': {
                        borderBottom: '1px solid rgb(255, 61, 113) !important'
                    }
                },
                '&:hover': {
                    '& .MuiInputBase-root': {
                        '&:before': {
                            borderBottom: '2px solid rgb(255, 61, 113) !important'
                        },
                        '&:after': {
                            borderBottom: '2px solid rgb(255, 61, 113) !important'
                        }
                    }
                },
            }
        }
    };
};

class W39F1011 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CriterionTitle: '',
            EmployeeInfo: null,
            errorMessage: false,

            loading: true,
            loading2: false,
            uploading: false,
            getResultSuccess: false,
            iPermission: null,
            errors: {},
            avatarAttach: null,
            formValid: [],
            dataOldAttachments: [],
            IsSave: 1,
            criterionCboResultsError: {},
            prevIsSubmit: 0,
            IsEvaluation: 0,
            isOpenPopover: false,
        };
        this.isLoaded = false;
        this.dataDetail = null;
        this.attachments = [];
        this.deletedFile = [];
        this.upload = null;
        this.timeout = null;
        this.EvaluationCombo = {};
        this.UserLevel = 1;
        this.TransTypeID = "";
        this.formData ={
            Mode: 0,
            ApproveLevel: 0,
            TransID: "",
            EmployeeID: "",
            DivisionID: "",
            EvaluationVoucherID: "",
            TranMonth: "",
            TranYear: "",
            Language: "",
            NoteEmployee: null,
            NoteEvaluatedLevel2: null,
            NoteEvaluatedLevel3: null,
            NoteEvaluatedLevel4: null,
            NoteEvaluatedLevel5: null,
            arrAttachment: [],
            ResultID1: null,
            ResultID2: null,
            ResultID3: null,
            ResultID4: null,
            ResultID5: null,
            detail: []
        };
        this.contentContainer = React.createRef();
        this.loaded = {};
        this.canvasHtml = null;
        this.isChangeForm = false;
        this.btnSaveRef = {};
        this.btnSendResultRef = {};
        this.buttonResultRef = {};
        this.idInput = '';
        this.timerGetFocus = null;
    }
    componentDidMount = async () => {

        const { location } = this.props;
        const {FormPermission, TransID, Mode, EvaluationVoucherID, EmployeeID, ApproveLevel, IsSave} = this.getInfo(); //FormID for get permission...

        const Language = Config.language || "84";
        const DivisionID = Config.getDivisionID();
        const FormID = _.get(location, "state.FormID", "W39F1011");
        const TransTypeID = _.get(location, "state.TransTypeID", "");
        this.UserLevel = _.get(location, "state.UserLevel", 1);
        this.TransTypeID = TransTypeID;

        await this.loadPermission(FormPermission ? FormPermission : FormID);

        if (!this.state.iPermission) return;

        if (
            ApproveLevel === null ||
            ![0, 1].includes(Mode) ||
            !FormID || FormID.length === 0 ||
            !TransID || TransID.length === 0 ||
            !EmployeeID || EmployeeID.length === 0 ||
            !EvaluationVoucherID || EvaluationVoucherID.length === 0
        ) {
            this.setState({ errorMessage: Config.lang("Khong_tim_thay_hoac_khong_du_tt") });
            return null;
        }

        this.formData.Mode = Mode;
        this.formData.ApproveLevel = ApproveLevel;
        this.formData.DivisionID = DivisionID;
        this.formData.Language = Language;
        this.formData.TransID = TransID;
        this.formData.FormID = FormID;
        this.formData.EmployeeID = EmployeeID;
        this.formData.EvaluationVoucherID = EvaluationVoucherID;
        this.formData.TranMonth = Config.getHRTransMonth();
        this.formData.TranYear = Config.getHRTransYear();

        // Show loading and hide when timeout
        this.setState({
            IsSave: IsSave,
            loading: true
        });
        this.timeout = setTimeout(()=>{
            this.isLoaded = true;
            this.setState({ loading: false });
        }, 3500);

        this.loaded = {
            loadMasterData: false,
            loadCriterionGroup: false,
            loadCriterionDetail: false,
            loadCriterionCombo: false,
        };
        // Call list APIs
        this.loadApi("loadMasterData", {FormID, Language, Mode, EvaluationVoucherID, TransID, EmployeeID, TransTypeID}, true);
        this.loadApi("loadCriterionGroup", {}, true);
        this.loadApi("loadCriterionDetail", {FormID, EvaluationVoucherID, TransID, EmployeeID, TransTypeID }, true);
        this.loadApi("loadCriterionCombo", {FormID, EvaluationVoucherID, EmployeeID, TransTypeID}, true);
        this.loadApi("loadHistoryApproval", { FormID, TransID, Language }, true);
        this.loadApi("getAttachmentsByTransID", { KeyID: TransID, Key02ID: EmployeeID });
        this.loadApi("getRequiredFields", {FormID: 'W39F1011', ModuleID: '39', CheckMode: 0 }, false);
    };
    componentWillUnmount = () => {
        this.setState({
            CriterionTitle: '',
            EmployeeInfo: null,
            errorMessage: false,

            loading: true,
            uploading: false,
            iPermission: 0,
            errors: {},
            avatarAttach: null,
            dataOldAttachments: [],
            IsSave: 1,
            prevIsSubmit: 0,
            IsEvaluation: 0,
        });
        this.isLoaded = false;
        this.dataDetail = null;
        this.attachments = [];
        this.deletedFile = [];
        this.upload = null;
        this.EvaluationCombo = {};
        this.UserLevel = 2;
        this.formData ={
            Mode: 0,
            ApproveLevel: 0,
            TransID: "",
            EmployeeID: "",
            DivisionID: "",
            EvaluationVoucherID: "",
            TranMonth: "",
            TranYear: "",
            Language: "",
            NoteEmployee: null,
            NoteEvaluatedLevel2: null,
            NoteEvaluatedLevel3: null,
            NoteEvaluatedLevel4: null,
            NoteEvaluatedLevel5: null,
            arrAttachment: [],
            ResultID1: null,
            ResultID2: null,
            ResultID3: null,
            ResultID4: null,
            ResultID5: null,
            detail: []
        };
        this.btnSaveRef = {};
        this.btnSendResultRef = {};

        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        if(this.timerGetFocus) clearTimeout(this.timerGetFocus);
    };

    getInfo = () => {
        const {location, isPopup} = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url?.get('TransID') || url?.get('FormPermission')) {
            return {
                TransID: url.get('TransID'),
                Mode: url.get('Mode') ?? null,
                EvaluationVoucherID: url.get('EvaluationVoucherID'),
                EmployeeID: url.get('EmployeeID'),
                ApproveLevel: url.get('ApproveLevel') ?? null,
                FormPermission: url.get('FormPermission'),
                IsSave: url.get('IsSave') ?? 1
            };
        } else {
            return {
                TransID: location?.state?.TransID || isPopup?.TransID || '',
                Mode: location?.state?.Mode ?? isPopup?.Mode ?? null,
                EvaluationVoucherID: location?.state?.EvaluationVoucherID || isPopup?.EvaluationVoucherID || '',
                EmployeeID: location?.state?.EmployeeID || isPopup?.EmployeeID || '',
                ApproveLevel: location?.state?.ApproveLevel ?? isPopup?.ApproveLevel ?? null,
                FormPermission: location?.state?.FormPermission || isPopup?.FormPermission || '',
                IsSave: location?.state?.IsSave ?? isPopup?.IsSave ?? 1
            }
        }
    };

    loadPermission = async (FormID) => {
        const { location, isPopup } = this.props;
        if(location?.state?.menu?.Type === "ESS" || FormID === "W39F2000"){
            this.setState({ iPermission: 4, loading: false });
            return await true;
        }
        await this.props.generalActions.getPermission(FormID ? FormID : "W39F1011", isPer => {
            this.setState({ iPermission: isPer, loading: false });
        }, FormID === "W39F2000" || !!isPopup);
    };
    onBack = () => {
        // browserHistory.goBack();
        const { location } = this.props;
        const FormID = _.get(location, "state.FormID", "W39F1010");
        let state = _.get(location, "state", {});
        state = {
            ...state,
            FormBack: "W39F2011"
        };

        browserHistory.push({
            pathname: Config.getRootPath() + FormID,
            state: state
        });
    };
    loadApi = (apiName, params = {}, ownAction = false) => {
        this.props[ownAction ? "W39F1011Actions" : "generalActions"][apiName](params, (error, data = null) => {
            if (error) {
                Config.popup2.show("ERROR", error);
                return false;
            }

            this.loaded[apiName] = true;

            if (apiName === "loadMasterData" && data) {
                if (data.IsEvaluateContract === 1) {
                    this.loadApi("loadComboResults", {Language: Config.language || "84"}, true);
                }
                if (data.EmployeeID) {
                    this.setState({
                        EmployeeInfo: Config.getUser({EmployeeID: data.EmployeeID}),
                        CriterionTitle: data.TransTypeName,
                        Note: data.Note || "",
                    });
                }
                const prevIsSubmit = data?.[`IsSubmit${Number(this.UserLevel) - 1}`] ?? 0;
                if (prevIsSubmit) {
                    this.setState({ prevIsSubmit: prevIsSubmit });
                }
                const IsEvaluation = this.UserLevel > 1 ? data?.[`IsEvaluatedLevel${this.UserLevel}`] ?? 0 : data?.IsEvaluation ?? 0;
                if (IsEvaluation) {
                    this.setState({ IsEvaluation: IsEvaluation });
                }
            }
            if (apiName === "getAttachmentsByTransID" && !_.isEmpty(data)) {
                if (data && data.length > 0) {
                    this.setState({
                        dataOldAttachments: data
                    });
                }
            }
        });
    };

    /************************************/
    /* FORM MODULE FUNCTIONS            */
    /************************************/
    setStateErrorText(objValue, key) {
        const stateError = key ? key : "errors";
        this.setState({
            [stateError]: { ...(this.state[stateError] ? this.state[stateError] : {}), ...objValue }
        });
        return Object.keys(objValue).length !== 0;
    }
    deletedAttachment = URL => {
        if (!_.isEmpty(URL) && this.deletedFile.filter(e => e.URL === URL).length === 0)
            this.deletedFile = [...this.deletedFile, { URL }];
    };
    onRemoveFile = () => {
        const { getForm } = this.props;
        const UserPictureURL = _.get(getForm, "UserPictureURL", "");
        this.deletedAttachment(UserPictureURL);
        this.setState({
            avatarAttach: null,
            formData: {
                ...this.state.formData,
                UserPictureURL: ""
            }
        });
    };
    ignoreReminder = () => {
        this.props.mainActions.ignoreReminder(_.get(this.props, "location.state.dataReminder", {}), (err) => {
            if (err) {
                Config.popup2.show("ERROR", err);
                return false;
            }
        })
    }
    checkDetailData = () => {
        if (this.dataDetail) {
            let isNotUpdated = false;
            for (let i = 0; i < this.dataDetail.length; i++) {
                const detail = this.dataDetail[i];
                if (!detail.Value && !detail.IsUpdated && this.UserLevel === 1) isNotUpdated = true;
                if (!detail.Value02 && !detail.IsUpdated02 && this.UserLevel === 2) isNotUpdated = true;
                if (!detail.Value03 && !detail.IsUpdated03 && this.UserLevel === 3) isNotUpdated = true;
                if (!detail.Value04 && !detail.IsUpdated04 && this.UserLevel === 4) isNotUpdated = true;
                if (!detail.Value05 && !detail.IsUpdated05 && this.UserLevel === 5) isNotUpdated = true;
                if (isNotUpdated) break;
            }
            return isNotUpdated;
        }
        return true;
    };
    onSave = () => {
        const {dataMasterData, getRequiredFields} = this.props;
        let LabelLevel1 = dataMasterData?.LabelLevel1 ? dataMasterData.LabelLevel1 :'';
        let LabelLevel2 = dataMasterData?.LabelLevel2 ? dataMasterData.LabelLevel2 :'';
        let LabelLevel3 = dataMasterData?.LabelLevel3 ? dataMasterData.LabelLevel3 :'';
        let LabelLevel4 = dataMasterData?.LabelLevel4 ? dataMasterData.LabelLevel4 :'';
        let LabelLevel5 = dataMasterData?.LabelLevel5 ? dataMasterData.LabelLevel5 :'';
        const dataRequireFields = [];
        let isDetailDataValid = false;
        let detailDataValidMode = "C";
        // Step 2: Check form valida
        if (this.state.formValid && this.state.formValid.length > 0) {
            Config.popup2.show("INFO", Config.lang("Tham_so_truyen_vao_khong_hop_le"));
            return false;
        }

        //Step 2.1: Check results when isEvaluateContract..
        if (dataMasterData && dataMasterData.IsEvaluateContract === 1) {
            let isCheckResultValid = true;
            const {ResultID1, ResultID2, ResultID3, ResultID4, ResultID5} = this.formData;
            let criterionCboResultsError = {};
            switch (this.UserLevel) {
                case 1:
                    if (!ResultID1) isCheckResultValid = false;
                    criterionCboResultsError.ResultID1 = true;
                    break;
                case 2:
                    if (!ResultID2) isCheckResultValid = false;
                    criterionCboResultsError.ResultID2 = true;
                    break;
                case 3:
                    if (!ResultID3) isCheckResultValid = false;
                    criterionCboResultsError.ResultID3 = true;
                    break;
                case 4:
                    if (!ResultID4) isCheckResultValid = false;
                    criterionCboResultsError.ResultID4 = true;
                    break;
                case 5:
                    if (!ResultID5) isCheckResultValid = false;
                    criterionCboResultsError.ResultID5 = true;
                    break;
                default:
                    break;
            }
            if (!isCheckResultValid) {
                this.setState({criterionCboResultsError: criterionCboResultsError});
                Config.popup2.show("INFO", Config.lang("Ban_phai_chon_ket_qua_danh_gia"));
                return false;
            }
        }
        // check require field
        getRequiredFields && getRequiredFields.forEach(field => {
            if(field?.ValidMode  === "O" &&
                this.formData.hasOwnProperty(field?.SqlFieldName) &&
                this.formData[field?.SqlFieldName] === "")
            {
                if(field?.SqlFieldName?.includes(2) && this.UserLevel === 2) {
                    dataRequireFields.push(LabelLevel2)
                } else if (field?.SqlFieldName?.includes(3) && this.UserLevel === 3) {
                    dataRequireFields.push(LabelLevel3)
                } else if (isNaN(field?.SqlFieldName?.substr(-1)) && this.UserLevel === 1) {
                    dataRequireFields.push(LabelLevel1);
                } else if (field?.SqlFieldName?.includes(4) && this.UserLevel === 4) {
                    dataRequireFields.push(LabelLevel4);
                } else if (field?.SqlFieldName?.includes(5) && this.UserLevel === 5) {
                    dataRequireFields.push(LabelLevel5);
                }
            }
            if (field?.SqlFieldName === "EvaluatedAllCriteria") {
                isDetailDataValid = this.checkDetailData();
                detailDataValidMode = field?.ValidMode ?? "C";
            }
        });


        if(!_.isEmpty(dataRequireFields)) {
            let message = Config.lang("TruongB") + " " +
                dataRequireFields[0] + " " + Config.lang("Bat_buoc_nhapB").toLowerCase();
            Config.popup2.show("INFO", message);
            return false;
        }

        // Step 2.2: Check detail data
        if (isDetailDataValid && detailDataValidMode === "O") {
            Config.popup.show("INFO", Config.lang("Ton_tai_chi_tieu_chua_duoc_danh_gia._Ban_khong_the_luu."));
            return false;
        } else if (isDetailDataValid && detailDataValidMode === "W") {
            Config.popup.show("YES_NO", Config.lang("Ton_tai_chi_tieu_chua_duoc_danh_gia._Ban_co_muon_luu_khong?"), () => {
                this._saveData();
            });
        } else {
            this._saveData();
        }
    };
    _saveData = () => {
        const {location, getRequiredFields, dataMasterData} = this.props;
        let LabelLevel1 = dataMasterData?.LabelLevel1 ? dataMasterData.LabelLevel1 :'';
        let LabelLevel2 = dataMasterData?.LabelLevel2 ? dataMasterData.LabelLevel2 :'';
        let LabelLevel3 = dataMasterData?.LabelLevel3 ? dataMasterData.LabelLevel3 :'';
        let LabelLevel4 = dataMasterData?.LabelLevel4 ? dataMasterData.LabelLevel4 :'';
        let LabelLevel5 = dataMasterData?.LabelLevel5 ? dataMasterData.LabelLevel5 :'';
        const dataWarningFields = [];
        // Step 1: Convert property CriterionDetail object to detail array
        this.formData.TransTypeID = this.TransTypeID;
        this.formData.detail = this.dataDetail;
        this.formData.arrAttachment = this._getAttachments();

        // check warning field
        getRequiredFields && getRequiredFields.forEach(field => {
            if(field?.ValidMode  === "W" &&
                this.formData.hasOwnProperty(field?.SqlFieldName) &&
                this.formData[field?.SqlFieldName] === "")
            {
                if(field?.SqlFieldName?.includes(2) && this.UserLevel === 2) {
                    dataWarningFields.push(LabelLevel2)
                } else if (field?.SqlFieldName?.includes(3) && this.UserLevel === 3) {
                    dataWarningFields.push(LabelLevel3)
                } else if (isNaN(field?.SqlFieldName?.substr(-1)) && this.UserLevel === 1) {
                    dataWarningFields.push(LabelLevel1);
                } else if (field?.SqlFieldName?.includes(4) && this.UserLevel === 4) {
                    dataWarningFields.push(LabelLevel4);
                } else if (field?.SqlFieldName?.includes(5) && this.UserLevel === 5) {
                    dataWarningFields.push(LabelLevel5);
                }
            }
        });

        if(!_.isEmpty(dataWarningFields)) {
            let message = dataWarningFields[0] + " " + Config.lang("Chua_duoc_nhap_ban_co_muon_luu_khong");
            Config.popup2.show({
                yesText: Config.lang("Tiep_tuc"),
                noText: Config.lang("Khong"),
                type: "yesno"},
                message,
                () => {
                    this.confirmSaveData(this.formData, location)
                }, null);
        } else {
            this.confirmSaveData(this.formData, location)
        }

    };

    confirmSaveData = (dataForm, location) => {
        // Step 3: Submit to server
        this.setState({loading2: true});
        this.props.W39F1011Actions["save"]({ ...dataForm, Mode: 0 }, (error, data) => {
            this.setState({loading2: false});
            if (error) {
                let message = Config.lang("Loi_chua_xac_dinh");
                switch (error.code) {
                    case "ERR000":
                        message = "DivisionID " + Config.lang("Bat_buoc");
                        break;
                    case "RGE002":
                        message = "TransID " + Config.lang("Bat_buoc");
                        break;
                    case "ERR003":
                        message = "CriterionDetail " + Config.lang("Bat_buoc");
                        break;
                    case "ERR006":
                        message = "Attachment " + Config.lang("Luu_khong_thanh_cong");
                        break;
                    case "null":
                        message = "URL " + Config.lang("Bat_buoc");
                        break;
                    default:
                        message = Config.lang("Luu_khong_thanh_cong");
                        break;
                }
                Config.popup2.show("INFO", message);
                return false;
            }

            if (data?.Status === 1) {
                let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                Config.popup2.show("INFO", message);
            }
            else if (data?.Status === 0) {
                this.timeout = setTimeout(()=>{
                    this.isChangeForm = false;
                    const {Mode, Language, TransID, FormID, EmployeeID, EvaluationVoucherID} = this.formData;
                    const TransTypeID = this.TransTypeID;
                    this._removeCDN();
                    this.loaded = {
                        ...this.loaded,
                        loadMasterData: false,
                        loadCriterionGroup: false,
                        loadCriterionDetail: false,
                    };
                    this.canvasImage = null;
                    this.loadApi("loadMasterData", {FormID, Language, Mode, EvaluationVoucherID, TransID, EmployeeID, TransTypeID}, true);
                    this.loadApi("loadCriterionGroup", {}, true);
                    this.loadApi("loadCriterionDetail", {FormID, EvaluationVoucherID, TransID, EmployeeID, TransTypeID }, true);
                    this.loadApi("getRequiredFields", {FormID: 'W39F1011', ModuleID: '39', CheckMode: 0 }, false);
                    // this.loadApi("getAttachmentsByTransID", { KeyID: TransID, Key02ID: EmployeeID });
                    // this.onBack();
                    setTimeout(() => {
                        this.isSaved = true;
                    }, 500); //delay 1s wait for data is loaded
                }, 700);
                Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                if(_.get(location,"state.dataReminder.KeyID", false)) this.ignoreReminder();
            }
        });
    };
    onChange = (e, name, data, isGetCalResult = false) => {
        const { IsSave } = this.state;
        const value = _.get(e, "value", _.get(e, "target.value", e ?? ""));

        if (["NoteEmployee", "NoteEvaluatedLevel2", "NoteEvaluatedLevel3", "NoteEvaluatedLevel4", "NoteEvaluatedLevel5",
            "ResultID1", "ResultID2", "ResultID3", "ResultID4", "ResultID5"].includes(name)) {
            // Change value of master data
            this.formData[name] = value;
        } else if (data) {
            // Change value of detail data
            for (let i = 0; i < this.dataDetail.length; i++) {
                const detail = this.dataDetail[i];

                if (
                    data.EvaluationElementID &&
                    detail.EvaluationElementID &&
                    data.EvaluationElementID === detail.EvaluationElementID
                ) {
                    // Trong trường hợp là combo thì update lại data
                    if ( _.isString(value) && value.includes("~")) {
                        const vl = value.split("~");
                        switch (name) {
                            case "Value02":
                                detail["Type02ID"] = vl[0];
                                detail["IsUpdated02"] = 1;
                                break;
                            case "Value03":
                                detail["Type03ID"] = vl[0];
                                detail["IsUpdated03"] = 1;
                                break;
                            case "Value04":
                                detail["Type04ID"] = vl[0];
                                detail["IsUpdated04"] = 1;
                                break;
                            case "Value05":
                                detail["Type05ID"] = vl[0];
                                detail["IsUpdated05"] = 1;
                                break;
                            default:
                                detail["TypeID"] = vl[0];
                                detail["IsUpdated"] = 1;
                                break;
                        }

                        detail[name] = vl[1];
                    } else {
                        switch (name) {
                            case "Value02":
                                detail["IsUpdated02"] = 1;
                                break;
                            case "Value03":
                                detail["IsUpdated03"] = 1;
                                break;
                            case "Value04":
                                detail["IsUpdated04"] = 1;
                                break;
                            case "Value05":
                                detail["IsUpdated05"] = 1;
                                break;
                            default:
                                detail["IsUpdated"] = 1;
                                break;
                        }
                        if (isGetCalResult) {
                            switch (this.formData.ApproveLevel) {
                                case 1:
                                    detail[name] = value.Value;
                                    detail["TypeName"] = value.TypeName;
                                    break;
                                case 2:
                                    detail["Value02"] = value.Value;
                                    detail["Type02Name"] = value.TypeName;
                                    detail["IsUpdated02"] = 1;
                                    break;
                                case 3:
                                    detail["Value03"] = value.Value;
                                    detail["Type03Name"] = value.TypeName;
                                    detail["IsUpdated03"] = 1;
                                    break;
                                case 4:
                                    detail["Value04"] = value.Value;
                                    detail["Type04Name"] = value.TypeName;
                                    detail["IsUpdated04"] = 1;
                                    break;
                                case 5:
                                    detail["Value05"] = value.Value;
                                    detail["Type05Name"] = value.TypeName;
                                    detail["IsUpdated05"] = 1;
                                    break;
                                default:
                                    break;
                            }
                        } else {
                            detail[name] = value;
                        }
                    }
                }

                this.dataDetail[i] = detail;
            }
        }
        if (isGetCalResult) this.setState({ getResultSuccess: true }); // Mục đích reRender UI
        if (IsSave === 1 && !this.isChangeForm && !_.isEmpty(this.btnSaveRef)) {
            this.isChangeForm = true;
            Object.keys(this.btnSaveRef).forEach(btn => {
                if (btn && this.btnSaveRef[btn]) this.btnSaveRef[btn].instance.option("disabled", false)
            });
        }
        if (this.isChangeForm && !_.isEmpty(this.btnSendResultRef)) {
            Object.keys(this.btnSendResultRef).forEach(btn => {
                if (btn && this.btnSendResultRef[btn]) this.btnSendResultRef[btn].instance.option("disabled", true)
            });
        }
    };
    onBlur = async (value, name, rule, data, num) => {

        if (rule === "Point") {
            this.inputFocus = null;
            await this.validCriterionPoint(name, value, data, num);
        }
    };
    validCriterionPoint = async (name, value, data, num) => {
        const {formValid} = this.state;
        const val = value ? value : "0";
        const Decimals = data.Decimals || 0;
        let _formValid = formValid.filter(f => !(f.field === name && f.criterionID === data.EvaluationElementID))
        let isValid = true;
        if (Number(Decimals) > 0) {
            isValid = validator.isDecimal(val, {decimal_digits: Decimals, locale: 'vi-VN'});
        } else {
            isValid = validator.isInt(val, {min: 0});
        }

        if (!isValid) {
            _formValid.push({
                field: name,
                criterionID: data.EvaluationElementID,
                error: true,
                message: Config.lang("Diem_so_khong_hop_le")
            });
        } else {
            if ((Number(data.MaxValue) > 0 && Number(val) > Number(data.MaxValue)) || (Number(val) < Number(data.MinValue))) {
                _formValid.push({
                    field: name,
                    criterionID: data.EvaluationElementID,
                    error: true,
                    message: data.MinValue  + " ≤ " + Config.lang("Gia_tri")  + " ≤ " + data.MaxValue
                });
            } else {
                if (data.EvaluationElementID && (data["Value" + (num ? num : "")] !== value)) {
                    const newType = await this.props.W39F1011Actions.getTypeEvaluation({
                        EvaluationElementID: data.EvaluationElementID,
                        Value: value || 0
                    });
                    if (newType) {
                        const ID = "Type" + (num ? num : "") + "ID";
                        const Name = "Type" + (num ? num : "") + "Name";
                        this.dataDetail = this.dataDetail?.map(d => {
                            if (d.EvaluationElementID === data.EvaluationElementID) {
                                d[ID] = newType.EvaluationLevelID;
                                d[Name] = newType.EvaluationLevelName;
                            }
                            return d;
                        });
                    }
                }
            }
        }
        if(this.timerGetFocus) clearTimeout(this.timerGetFocus);
        this.timerGetFocus = setTimeout(() => {
            this.idInput = document.querySelector(':focus')?.id || "";
            this.setState({formValid: _formValid});
        }, 200)
    };
    isError = (name, rule, data) => {
        const {formValid} = this.state;
        if (rule === "Point") {
            for (let i = 0; i < formValid.length; i++) {
                const fvl = formValid[i];

                if (fvl.field === name && fvl.criterionID === data.EvaluationElementID) {
                    return fvl.error;
                }
            }
        }

        return false;
    };
    getErrorMessage = (name, rule, data) => {
        const {formValid} = this.state;

        if (rule === "Point") {
            for (let i = 0; i < formValid.length; i++) {
                const fvl = formValid[i];

                if (fvl.field === name && fvl.criterionID === data.EvaluationElementID) {
                    return fvl.message;
                }
            }
        }

        return "";
    };

    /************************************/
    /* ATTACHMENT FUNCTIONS             */
    /************************************/
    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };
    onChangeAttachments = (e) => {
        this.isChangeForm = true;

        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState((prevState) => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                }),
            }));
        }
    };
    onUploading = value => {
        this.setState({ uploading: value });
    };
    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1],
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };
    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : "",
            });
        });
        if (!_.isEmpty(dataOldAttachments)) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    /************************************/
    /* RENDER FUNCTIONS                 */
    /************************************/
    parseDataGroupCriterion = () => {
        const { dataMasterData, dataCriterionGroup, dataCriterionDetail, dataCriterionCombo } = this.props;

        if (
            !dataMasterData ||
            !dataCriterionGroup ||
            dataCriterionGroup.length === 0 ||
            !dataCriterionDetail ||
            dataCriterionDetail.length === 0 ||
            !this.isLoaded
        ) {
            return null;
        }

        if (dataCriterionCombo && dataCriterionCombo.length > 0) {
            for (let i = 0; i < dataCriterionDetail.length; i++) {
                const dt = dataCriterionDetail[i];
                const sl = [];


                for (let j = 0; j < dataCriterionCombo.length; j++) {
                    const cb = dataCriterionCombo[j];

                    if (dt.EvaluationElementID === cb.EvaluationElementID) {
                        // We need 2 field to save, and merge its into the value with ~
                        sl.push({text: cb.EvaluationCodeName, value: cb.EvaluationCodeID+"~"+cb.Value, IsDisplayValue: cb.IsDisplayValue, IsTypeEvaluation: dt.IsTypeEvaluation, DisplayValue: cb.Value});
                    }
                }

                if (sl.length > 0) {
                    this.EvaluationCombo[dt.EvaluationElementID] = sl;
                }
            }
        }

        const criterion = [];
        const dataDetail = [];
        for (let i = 0; i < dataCriterionGroup.length; i++) {
            const group = dataCriterionGroup[i];
            const grCriterion = [];

            for (let j = 0; j < dataCriterionDetail.length; j++) {
                const detail = dataCriterionDetail[j];

                if (group.AppCriterionGroupID === detail.AppCriterionGroupID) {
                    grCriterion.push(detail);
                    dataDetail.push({
                        TypeID: detail.TypeID,
                        TypeName: detail.TypeName,
                        Value: detail.Value,
                        IsUpdated: 0,
                        Type02ID: detail.Type02ID,
                        Type02Name: detail.Type02Name,
                        Value02: detail.Value02,
                        IsUpdated02: 0,
                        Type03ID: detail.Type03ID,
                        Type03Name: detail.Type03Name,
                        Value03: detail.Value03,
                        IsUpdated03: 0,
                        Type04ID: detail.Type04ID,
                        Type04Name: detail.Type04Name,
                        Value04: detail.Value04,
                        IsUpdated04: 0,
                        Type05ID: detail.Type05ID,
                        Type05Name: detail.Type05Name,
                        Value05: detail.Value05,
                        IsUpdated05: 0,
                        Note1U: detail.Note1U,
                        Note2U: detail.Note2U,
                        Note3U: detail.Note3U,
                        Note4U: detail.Note4U,
                        Note5U: detail.Note5U,
                        IsTypeEvaluation: detail.IsTypeEvaluation,
                        EvaluationElementID: detail.EvaluationElementID,
                    });
                }
            }

            if (grCriterion.length > 0) {
                group.ListCriterionDetail = grCriterion;
                criterion.push(group);
            }
        }

        //set Result value when IsEvaluateContract = 1...
        if (dataMasterData.IsEvaluateContract === 1) {
            const {ResultID1, ResultID2, ResultID3, ResultID4, ResultID5} = this.formData;
            if (ResultID1 === null) {
                this.formData.ResultID1 = dataMasterData && dataMasterData.ResultID1 ? dataMasterData.ResultID1 : '';
            }
            if (ResultID2 === null) {
                this.formData.ResultID2 = dataMasterData && dataMasterData.ResultID2 ? dataMasterData.ResultID2 : '';
            }
            if (ResultID3 === null) {
                this.formData.ResultID3 = dataMasterData && dataMasterData.ResultID3 ? dataMasterData.ResultID3 : '';
            }
            if (ResultID4 === null) {
                this.formData.ResultID4 = dataMasterData && dataMasterData.ResultID4 ? dataMasterData.ResultID4 : '';
            }
            if (ResultID5 === null) {
                this.formData.ResultID5 = dataMasterData && dataMasterData.ResultID5 ? dataMasterData.ResultID5 : '';
            }
        }

        // For push data form props to this
        if (!this.dataDetail || this.isSaved) {
            this.dataDetail = [...dataDetail];
            if (this.isSaved) setTimeout(() => this.isSaved = false, 1000); //clear isSave when reload data;

            this.formData.NoteEmployee = dataMasterData && dataMasterData.NoteEmployee ? dataMasterData.NoteEmployee : '';
            this.formData.NoteEvaluatedLevel2 = dataMasterData && dataMasterData.NoteEvaluatedLevel2 ? dataMasterData.NoteEvaluatedLevel2 : '';
            this.formData.NoteEvaluatedLevel3 = dataMasterData && dataMasterData.NoteEvaluatedLevel3 ? dataMasterData.NoteEvaluatedLevel3 : '';
            this.formData.NoteEvaluatedLevel4 = dataMasterData && dataMasterData.NoteEvaluatedLevel4 ? dataMasterData.NoteEvaluatedLevel4 : '';
            this.formData.NoteEvaluatedLevel5 = dataMasterData && dataMasterData.NoteEvaluatedLevel5 ? dataMasterData.NoteEvaluatedLevel5 : '';
        }

        return criterion;
    };
    renderInputAdorment = (value) => {
        return <InputAdornment disableTypography={true}
                               component={"span"}
                               title={value || ""}
                               style={{whiteSpace: "nowrap", maxWidth: "50%", height: "100%"}}
                               position="end">
            <span style={{width: "100%", overflow: "hidden", textOverflow: "ellipsis"}}>{value || ""}</span>
        </InputAdornment>
    };
    renderUserProfile = (data) => {
        const {classes, dataMasterData} = this.props;
        const AppraisalDate = dataMasterData.AppraisalDate ? Config.convertDate(dataMasterData.AppraisalDate, "", "DD/MM/YYYY kk:mm:ss", true) : "";

        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} onClick={() => this.onChangePageProfile(data)}/>
                <div className={"w75f2000_info"}>
                    <div className={classes.divText}><UserName data={data}/></div>

                    <div className={classes.dateInfo}>
                        <Image src={require('../../../../assets/images/icon-calendar.svg')}/>
                        <div style={{paddingLeft:'8px'}} className={"date-text-info"}>{AppraisalDate}</div>
                    </div>
                    {dataMasterData && dataMasterData.IsEvaluateContract === 1 &&
                    <div className={classes.divText}>{dataMasterData.WorkFormName}</div>}
                </div>
            </div>
        )
    };


    renderCriterionCboResults = (name, disabled, level) => {
        if (!name || name.length < 1) {
            return null;
        }

        const {criterionCboResultsError} = this.state;
        const {dataComboResults, dataMasterData, location} = this.props;
        const FormID = location?.state?.FormID ?? "";
        let selected = this.formData && this.formData[name] ? this.formData[name] : "";
        let errorMessage = criterionCboResultsError[name] && !selected ? Config.lang("Vui_long_chon_ket_qua") : "";

        return (
            <FormControl error={!!errorMessage} fullWidth>
                <Dropdown
                    required
                    clearAble
                    dataSource={dataComboResults}
                    disabled={disabled}
                    displayExpr={"ResultName"}
                    valueExpr={"ResultID"}
                    defaultValue={dataMasterData?.NotViewOther && FormID === "W39F1010" ? (this.UserLevel === level ? (selected || "") : "") : (selected || "")}
                    onChange={(e) => {
                        const value = e.value;
                        this.onChange(value, name);
                        if (value) this.setState({criterionCboResultsError: {}});
                    }}
                />
                {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
            </FormControl>
        );
    };
    renderCriterionCombo = (code, name, detail, disabled, level) => {
        const { dataMasterData, location } = this.props;
        const FormID = location?.state?.FormID ?? "";
        if (!code || code.length < 1 || !this.EvaluationCombo[code]) {
            return null;
        }

        let data = [];

        if (this.EvaluationCombo[code] && this.EvaluationCombo[code].length > 0) {
            data = this.EvaluationCombo[code];
        }

        let selected = "";
        switch (name) {
            case "Value02":
                selected = detail.Type02ID && detail.Type02ID !== "NaN" ? detail.Type02ID+"~"+detail.Value02 : null;
                break;
            case "Value03":
                selected = detail.Type03ID && detail.Type03ID !== "NaN" ? detail.Type03ID+"~"+detail.Value03 : null;
                break;
            case "Value04":
                selected = detail.Type04ID && detail.Type04ID !== "NaN" ? detail.Type04ID+"~"+detail.Value04 : null;
                break;
            case "Value05":
                selected = detail.Type05ID && detail.Type05ID !== "NaN" ? detail.Type05ID+"~"+detail.Value05 : null;
                break;
            default:
                selected = detail.TypeID && detail.TypeID !== "NaN" ? detail.TypeID+"~"+detail.Value : null;
                break;
        }

        const renderItemWithValue = (dataSource) => {
            const { data } = dataSource;
            return (
                <div className="jus-between full_w">
                    <Typography>
                        {data.text}
                    </Typography>
                    {!!(data.IsDisplayValue && data.IsTypeEvaluation) && 
                        <Typography>
                            {data.DisplayValue}
                        </Typography>
                    }
                </div>
            )
        }

        return (
            <FormControl fullWidth>
                <Dropdown
                    clearAble
                    dataSource={data}
                    disabled={disabled}
                    displayExpr={"text"}
                    valueExpr={"value"}
                    renderSelectedItem={(dataSource) => renderItemWithValue(dataSource)}
                    renderItem={(dataSource) => renderItemWithValue(dataSource)}
                    defaultValue={dataMasterData?.NotViewOther && FormID === "W39F1010" ? (this.UserLevel === level ? (selected || "") : "") : (selected || "")}
                    onChange={(e) => this.onChange(e.value || "~0", name, detail, true)}
                />
            </FormControl>
        );
    };
    _setDefaultDetail = (value) => {
        return !this.isChangeForm ? value : typeof value === "number" ? 0 : "";

    };

    setFocusInput = (input, id) => {
        if(id === this.idInput && input) {
            const end = input?.value?.length || 0;
            input.setSelectionRange(end, end);
            input.focus();
            this.idInput = "";
        }
    };

    renderCriterionDetail = (data, level) => {
        const { dataMasterData, location , classes} = this.props;
        const FormID = location?.state?.FormID ?? "";
        const calculateWidthCol = {
            maxWidth: `calc(100% / ${level})`,
            flexBasis: `calc(100% / ${level})`,
        };
        const col = Math.floor(12 / level);
        let pdLevel1 = {padding: "0"};
        let pdLevel2 = {padding: "0"};
        let pdLevel3 = {padding: "0"};
        let pdLevel4 = {padding: "0"};
        let pdLevel5 = {padding: "0"};

        if (level === 2) {
            pdLevel1 = {padding: "0 10px 0 0"};
            pdLevel2 = {padding: "0 0 0 10px"};
            pdLevel3 = {padding: "0"};
        }
        if (level === 3) {
            pdLevel1 = {padding: "0 10px 0 0"};
            pdLevel2 = {padding: "0 10px 0 10px"};
            pdLevel3 = {padding: "0 0 0 10px"};
        }
        if (level === 4) {
            pdLevel1 = {padding: "0 10px 0 0"};
            pdLevel2 = {padding: "0 10px 0 10px"};
            pdLevel3 = {padding: "0 10px 0 10px"};
            pdLevel4 = {padding: "0 0 0 10px"};
        }
        if (level === 5) {
            pdLevel1 = {padding: "0 10px 0 0"};
            pdLevel2 = {padding: "0 10px 0 10px"};
            pdLevel3 = {padding: "0 10px 0 10px"};
            pdLevel4 = {padding: "0 10px 0 10px"};
            pdLevel5 = {padding: "0 0 0 10px"};
        }

        pdLevel1 = {
            ...pdLevel1,
            ...calculateWidthCol,
        };
        pdLevel2 = {
            ...pdLevel2,
            ...calculateWidthCol,
        };
        pdLevel3 = {
            ...pdLevel3,
            ...calculateWidthCol,
        };
        pdLevel4 = {
            ...pdLevel4,
            ...calculateWidthCol,
        };
        pdLevel5 = {
            ...pdLevel5,
            ...calculateWidthCol,
        };

        let typeNum = 0;
        let typeText = data.ResultDes || "";
        if (data && data.IsTypeEvaluation) {
            typeNum = parseInt(data.IsTypeEvaluation);
            typeNum = typeNum > 2 || typeNum < 0 ? 0 : typeNum;
        }
        if (!typeText) {
            switch (typeNum) {
                case 1:
                    typeText = Config.lang("Xep_loai");
                    break;
                case 2:
                    typeText = Config.lang("Cong_thuc");
                    break;
                default:
                    typeText = Config.lang("Diem");
                    break;
            }
        }

        // Get data for change
        let changed = {};
        for (let i = 0; i < this.dataDetail.length; i++) {
            const detail = this.dataDetail[i];
            if (
                data.EvaluationElementID &&
                detail.EvaluationElementID &&
                data.EvaluationElementID === detail.EvaluationElementID
            ) {
                changed = detail;
            }
        }

        // For detail data with submitting form
        const detail = {
            TypeID: !Config.isEmpty(changed.TypeID) ? changed.TypeID : this._setDefaultDetail(data.TypeID),
            TypeName: !Config.isEmpty(changed.TypeName) ? changed.TypeName : this._setDefaultDetail(data.TypeName),
            Value: !Config.isEmpty(changed.Value) ? changed.Value : this._setDefaultDetail(data.Value),
            Type02ID: !Config.isEmpty(changed.Type02ID) ? changed.Type02ID : this._setDefaultDetail(data.Type02ID),
            Type02Name: !Config.isEmpty(changed.Type02Name) ? changed.Type02Name : this._setDefaultDetail(data.Type02Name),
            Value02: !Config.isEmpty(changed.Value02) ? changed.Value02 : this._setDefaultDetail(data.Value02),
            Type03ID: !Config.isEmpty(changed.Type03ID) ? changed.Type03ID : this._setDefaultDetail(data.Type03ID),
            Type03Name: !Config.isEmpty(changed.Type03Name) ? changed.Type03Name : this._setDefaultDetail(data.Type03Name),
            Value03: !Config.isEmpty(changed.Value03) ? changed.Value03 : this._setDefaultDetail(data.Value03),
            Type04ID: !Config.isEmpty(changed.Type04ID) ? changed.Type04ID : this._setDefaultDetail(data.Type04ID),
            Type04Name: !Config.isEmpty(changed.Type04Name) ? changed.Type04Name : this._setDefaultDetail(data.Type04Name),
            Value04: !Config.isEmpty(changed.Value04) ? changed.Value04 : this._setDefaultDetail(data.Value04),
            Type05ID: !Config.isEmpty(changed.Type05ID) ? changed.Type05ID : this._setDefaultDetail(data.Type05ID),
            Type05Name: !Config.isEmpty(changed.Type05Name) ? changed.Type05Name : this._setDefaultDetail(data.Type05Name),
            Value05: !Config.isEmpty(changed.Value05) ? changed.Value05 : this._setDefaultDetail(data.Value05),
            Note1U: !Config.isEmpty(changed.Note1U) ? changed.Note1U : this._setDefaultDetail(data.Note1U),
            Note2U: !Config.isEmpty(changed.Note2U) ? changed.Note2U : this._setDefaultDetail(data.Note2U),
            Note3U: !Config.isEmpty(changed.Note3U) ? changed.Note3U : this._setDefaultDetail(data.Note3U),
            Note4U: !Config.isEmpty(changed.Note4U) ? changed.Note4U : this._setDefaultDetail(data.Note4U),
            Note5U: !Config.isEmpty(changed.Note5U) ? changed.Note5U : this._setDefaultDetail(data.Note5U),
            EvaluationElementID: data.EvaluationElementID,
            MaxValue: data.MaxValue ? data.MaxValue : 0,
            Decimals: data.Decimals ? data.Decimals : 0,
            MinValue: data.MinValue ? data.MinValue : 0,
        };

        const idNote1U = `Note1U${data.OrderNo}`;
        const idNote2U = `Note2U${data.OrderNo}`;
        const idNote3U = `Note3U${data.OrderNo}`;
        const idNote4U = `Note4U${data.OrderNo}`;
        const idNote5U = `Note5U${data.OrderNo}`;

        const idValue = `Value${data.OrderNo}`;
        const idValue02 = `Value02${data.OrderNo}`;
        const idValue03 = `Value03${data.OrderNo}`;
        const idValue04 = `Value04${data.OrderNo}`;
        const idValue05 = `Value05${data.OrderNo}`;
        return (
            <span key={data.EvaluationElementID} style={{display: 'flex', alignItems: 'center'}}>
                <Grid
                key={Math.random().toString(36).substring(7)}
                container
                spacing={0}
                direction="row"
                justify="flex-end"
                alignItems="flex-start"
                style={{marginBottom: 20}}>
                <Grid item xs={10} style={{padding: "0 auto"}}>
                    <div>{data.OrderNo + ". " + data.EvaluationElementName}</div>
                    {data.Note && <div style={{whiteSpace: 'pre-line', fontStyle: "italic", fontSize: 13}}>{data.Note}</div>}
                </Grid>
                <Grid item xs={2} style={{padding: "0 auto"}}>
                    <div className={"display_row align-center valign-bottom"}>
                        {data && Number(data.Proportion) > 0 && <Chip size="small" label={String(data.Proportion)} className={"mgr5"} />}
                        <div style={{textAlign:"right"}}>({typeText})</div>
                    </div>
                </Grid>
                <hr style={{marginTop: 3, marginBottom: 5, width: "100%"}} />

                {level >= 1 &&
                <Grid item xs={col} style={pdLevel1}>
                    {typeNum === 0 &&
                    <NumberFormat
                        className={this.isError("Value", "Point", detail) ? classes.validateField :""}
                        customInput={props => <TextField inputRef={ref => this.setFocusInput(ref, idValue)} {...props} />}
                        thousandSeparator={false}
                        decimalScale={detail.Decimals || 0}
                        // value={state[dataField] ? String(state[dataField]) : dataOldForm['TempBaseSalary' + no]}
                        fullWidth
                        disabled={this.UserLevel !== 1}
                        defaultValue={dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 1 && detail.Value : detail.Value}
                        InputLabelProps={{
                            shrink: true
                        }}
                        variant={"standard"}
                        margin={"normal"}
                        error={this.isError("Value", "Point", detail)}
                        InputProps={{
                            id: idValue,
                            endAdornment: dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 1 && this.renderInputAdorment(detail.TypeName) : this.renderInputAdorment(detail.TypeName),
                        }}
                        helperText={this.getErrorMessage("Value", "Point", detail)}
                        onBlur={async (e) => await this.onBlur(e.target.value, "Value", "Point", detail, "")}
                        onValueChange={(e) => this.onChange(e.value || 0, "Value", detail)}
                        style={{marginTop: 2}}
                        inputProps={{min: "0", step: "1"}}
                    />}
                    {typeNum === 1 && this.renderCriterionCombo(detail.EvaluationElementID, "Value", detail, this.UserLevel !== 1, 1)}
                    {typeNum === 2 &&
                    <NumberFormat
                        customInput={TextField}
                        thousandSeparator={false}
                        decimalScale={detail.Decimals || 0}
                        // value={state[dataField] ? String(state[dataField]) : dataOldForm['TempBaseSalary' + no]}
                        fullWidth
                        disabled={true}
                        defaultValue={dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 1 && detail.Value : detail.Value}
                        variant={"standard"}
                        margin={"normal"}
                        InputProps={{
                            endAdornment: dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 1 && this.renderInputAdorment(detail.TypeName) : this.renderInputAdorment(detail.TypeName),
                        }}
                        style={{marginTop: 2}}
                    />}
                </Grid>
                }
                {level >= 2 &&
                <Grid item xs={col} style={pdLevel2}>
                    {typeNum === 0 &&
                    <NumberFormat
                        customInput={props => <TextField inputRef={ref => this.setFocusInput(ref, idValue02)} {...props} />}
                        thousandSeparator={false}
                        decimalScale={detail.Decimals || 0}
                        // value={state[dataField] ? String(state[dataField]) : dataOldForm['TempBaseSalary' + no]}
                        fullWidth
                        disabled={this.UserLevel !== 2}
                        defaultValue={dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 2 && detail.Value02 : detail.Value02}
                        InputLabelProps={{
                            shrink: true
                        }}
                        variant={"standard"}
                        margin={"normal"}
                        error={this.isError("Value02", "Point", detail)}
                        InputProps={{
                            id: idValue02,
                            endAdornment: dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 2 && this.renderInputAdorment(detail.Type02Name) : this.renderInputAdorment(detail.Type02Name),
                        }}
                        helperText={this.getErrorMessage("Value02", "Point", detail)}
                        onBlur={async (e) => await this.onBlur(e.target.value, "Value02", "Point", detail, "02")}
                        onValueChange={(e) => this.onChange(e.value || 0, "Value02", detail)}
                        style={{marginTop: 2}}
                        inputProps={{min: "0", step: "1"}}
                    />}
                    {typeNum === 1 && this.renderCriterionCombo(detail.EvaluationElementID, "Value02", detail, this.UserLevel !== 2, 2)}
                    {typeNum === 2 &&
                    <NumberFormat
                        customInput={TextField}
                        thousandSeparator={false}
                        decimalScale={detail.Decimals || 0}
                        // value={state[dataField] ? String(state[dataField]) : dataOldForm['TempBaseSalary' + no]}
                        fullWidth
                        disabled={true}
                        defaultValue={dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 2 && detail.Value02 : detail.Value02}
                        variant={"standard"}
                        margin={"normal"}
                        InputProps={{
                            endAdornment: dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 2 && this.renderInputAdorment(detail.Type02Name) : this.renderInputAdorment(detail.Type02Name),
                        }}
                        style={{marginTop: 2}}
                    />}
                </Grid>
                }
                {level >= 3 &&
                <Grid item xs={col} style={pdLevel3}>
                    {typeNum === 0 &&
                    <NumberFormat
                        customInput={props => <TextField inputRef={ref => this.setFocusInput(ref, idValue03)} {...props} />}
                        thousandSeparator={false}
                        decimalScale={detail.Decimals || 0}
                        // value={state[dataField] ? String(state[dataField]) : dataOldForm['TempBaseSalary' + no]}
                        fullWidth
                        disabled={this.UserLevel !== 3}
                        defaultValue={dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 3 && detail.Value03 : detail.Value03}
                        InputLabelProps={{
                            shrink: true
                        }}
                        variant={"standard"}
                        margin={"normal"}
                        error={this.isError("Value03", "Point", detail)}
                        InputProps={{
                            id: idValue03,
                            endAdornment: dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 3 && this.renderInputAdorment(detail.Type03Name) : this.renderInputAdorment(detail.Type03Name),
                        }}
                        helperText={this.getErrorMessage("Value03", "Point", detail)}
                        onBlur={async (e) => await this.onBlur(e.target.value, "Value03", "Point", detail, "03")}
                        onValueChange={(e) => this.onChange(e.value || 0, "Value03", detail)}
                        style={{marginTop: 2}}
                        inputProps={{min: "0", step: "1"}}
                    />}
                    {typeNum === 1 && this.renderCriterionCombo(detail.EvaluationElementID, "Value03", detail, this.UserLevel !== 3, 3)}
                    {typeNum === 2 &&
                    <NumberFormat
                        customInput={TextField}
                        thousandSeparator={false}
                        decimalScale={detail.Decimals || 0}
                        // value={state[dataField] ? String(state[dataField]) : dataOldForm['TempBaseSalary' + no]}
                        fullWidth
                        disabled={true}
                        defaultValue={dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 3 && detail.Value03 : detail.Value03}
                        variant={"standard"}
                        margin={"normal"}
                        InputProps={{
                            endAdornment: dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 3 && this.renderInputAdorment(detail.Type03Name) : this.renderInputAdorment(detail.Type03Name),
                        }}
                        style={{marginTop: 2}}
                    />}
                </Grid>
                }
                {level >= 4 &&
                <Grid item xs={col} style={pdLevel4}>
                    {typeNum === 0 &&
                    <NumberFormat
                        customInput={props => <TextField inputRef={ref => this.setFocusInput(ref, idValue04)} {...props} />}
                        thousandSeparator={false}
                        decimalScale={detail.Decimals || 0}
                        // value={state[dataField] ? String(state[dataField]) : dataOldForm['TempBaseSalary' + no]}
                        fullWidth
                        disabled={this.UserLevel !== 4}
                        defaultValue={dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 4 && detail.Value04 : detail.Value04}
                        InputLabelProps={{
                            shrink: true
                        }}
                        variant={"standard"}
                        margin={"normal"}
                        error={this.isError("Value04", "Point", detail)}
                        InputProps={{
                            id: idValue04,
                            endAdornment: dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 4 && this.renderInputAdorment(detail.Type04Name) : this.renderInputAdorment(detail.Type04Name),
                        }}
                        helperText={this.getErrorMessage("Value04", "Point", detail)}
                        onBlur={async (e) => await this.onBlur(e.target.value, "Value04", "Point", detail, "04")}
                        onValueChange={(e) => this.onChange(e.value || 0, "Value04", detail)}
                        style={{marginTop: 2}}
                        inputProps={{min: "0", step: "1"}}
                    />}
                    {typeNum === 1 && this.renderCriterionCombo(detail.EvaluationElementID, "Value04", detail, this.UserLevel !== 4, 4)}
                    {typeNum === 2 &&
                    <NumberFormat
                        customInput={TextField}
                        thousandSeparator={false}
                        decimalScale={detail.Decimals || 0}
                        // value={state[dataField] ? String(state[dataField]) : dataOldForm['TempBaseSalary' + no]}
                        fullWidth
                        disabled={true}
                        defaultValue={dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 4 && detail.Value04 : detail.Value04}
                        variant={"standard"}
                        margin={"normal"}
                        InputProps={{
                            endAdornment: dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 4 && this.renderInputAdorment(detail.Type04Name) : this.renderInputAdorment(detail.Type04Name),
                        }}
                        style={{marginTop: 2}}
                    />}
                </Grid>
                }
                {level >= 5 &&
                <Grid item xs={col} style={pdLevel5}>
                    {typeNum === 0 &&
                    <NumberFormat
                        customInput={props => <TextField inputRef={ref => this.setFocusInput(ref, idValue05)} {...props} />}
                        thousandSeparator={false}
                        decimalScale={detail.Decimals || 0}
                        // value={state[dataField] ? String(state[dataField]) : dataOldForm['TempBaseSalary' + no]}
                        fullWidth
                        disabled={this.UserLevel !== 5}
                        defaultValue={dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 5 && detail.Value05 : detail.Value05}
                        InputLabelProps={{
                            shrink: true
                        }}
                        variant={"standard"}
                        margin={"normal"}
                        error={this.isError("Value05", "Point", detail)}
                        InputProps={{
                            id: idValue05,
                            endAdornment: dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 5 && this.renderInputAdorment(detail.Type05Name) : this.renderInputAdorment(detail.Type05Name),
                        }}
                        helperText={this.getErrorMessage("Value05", "Point", detail)}
                        onBlur={async (e) => await this.onBlur(e.target.value, "Value05", "Point", detail, "05")}
                        onValueChange={(e) => this.onChange(e.value || 0, "Value05", detail)}
                        style={{marginTop: 2}}
                        inputProps={{min: "0", step: "1"}}
                    />}
                    {typeNum === 1 && this.renderCriterionCombo(detail.EvaluationElementID, "Value05", detail, this.UserLevel !== 5, 5)}
                    {typeNum === 2 &&
                    <NumberFormat
                        customInput={TextField}
                        thousandSeparator={false}
                        decimalScale={detail.Decimals || 0}
                        // value={state[dataField] ? String(state[dataField]) : dataOldForm['TempBaseSalary' + no]}
                        fullWidth
                        disabled={true}
                        defaultValue={dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 5 && detail.Value05 : detail.Value05}
                        variant={"standard"}
                        margin={"normal"}
                        InputProps={{
                            endAdornment: dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 5 && this.renderInputAdorment(detail.Type05Name) : this.renderInputAdorment(detail.Type05Name),
                        }}
                        style={{marginTop: 2}}
                    />}
                </Grid>
                }

                {level >= 1 &&
                <Grid item xs={col} style={pdLevel1} container spacing={0} alignItems={"stretch"} className={"mgt2x"}>
                    <Grid item style={{ paddingTop: 6 }}>
                        <Icon name={"Pen"} style={{ color: "#9E9E9E" }} />
                    </Grid>
                    <Grid item style={{ flex: 1, minWidth: 0 }}>
                        <TextInput
                            inputProps={{ id: idNote1U }}
                            inputRef={(ref) => this.setFocusInput(ref, idNote1U)}
                            maxRows={5}
                            multiline={true}
                            style={{ fontStyle: "italic"}}
                            defaultValue={dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 1 ? detail.Note1U : '' : detail.Note1U}
                            disabled={this.UserLevel !== 1}
                            onChange={(value) => this.onChange(value, "Note1U", detail)}
                        />
                    </Grid>
                </Grid>
                }
                {level >= 2 &&
                <Grid item xs={col} style={pdLevel2} container spacing={0} alignItems={"stretch"} className={"mgt2x"}>
                    <Grid item style={{ paddingTop: 6 }}>
                        <Icon name={"Pen"} style={{ color: "#9E9E9E" }} />
                    </Grid>
                    <Grid item style={{ flex: 1, minWidth: 0 }}>
                        <TextInput
                            inputProps={{ id: idNote2U }}
                            inputRef={(ref) => this.setFocusInput(ref, idNote2U)}
                            maxRows={5}
                            multiline={true}
                            style={{ fontStyle: "italic" }}
                            defaultValue={dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 2 ? detail.Note2U : '' : detail.Note2U}
                            disabled={this.UserLevel !== 2}
                            onChange={(value) => this.onChange(value, "Note2U", detail)}
                        />
                    </Grid>
                </Grid>
                }
                {level >= 3 &&
                <Grid item xs={col} style={pdLevel3} container spacing={0} alignItems={"stretch"} className={"mgt2x"}>
                    <Grid item style={{ paddingTop: 6 }}>
                        <Icon name={"Pen"} style={{ color: "#9E9E9E" }} />
                    </Grid>
                    <Grid item style={{ flex: 1, minWidth: 0 }}>
                        <TextInput
                            inputProps={{ id: idNote3U }}
                            inputRef={(ref) => this.setFocusInput(ref, idNote3U)}
                            maxRows={5}
                            multiline={true}
                            style={{ fontStyle: "italic" }}
                            defaultValue={dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 3 ? detail.Note3U : '' : detail.Note3U}
                            disabled={this.UserLevel !== 3}
                            onChange={(value) => this.onChange(value, "Note3U", detail)}
                        />
                    </Grid>
                </Grid>
                }
                {level >= 4 &&
                <Grid item xs={col} style={pdLevel4} container spacing={0} alignItems={"stretch"} className={"mgt2x"}>
                    <Grid item style={{ paddingTop: 6 }}>
                        <Icon name={"Pen"} style={{ color: "#9E9E9E" }} />
                    </Grid>
                    <Grid item style={{ flex: 1, minWidth: 0 }}>
                        <TextInput
                            inputProps={{ id: idNote4U }}
                            inputRef={(ref) => this.setFocusInput(ref, idNote4U)}
                            maxRows={5}
                            multiline={true}
                            style={{ fontStyle: "italic" }}
                            defaultValue={dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 4 ? detail.Note4U : '' : detail.Note4U}
                            disabled={this.UserLevel !== 4}
                            onChange={(value) => this.onChange(value, "Note4U", detail)}
                        />
                    </Grid>
                </Grid>
                }
                {level >= 5 &&
                <Grid item xs={col} style={pdLevel5} container spacing={0} alignItems={"stretch"} className={"mgt2x"}>
                    <Grid item style={{ paddingTop: 6 }}>
                        <Icon name={"Pen"} style={{ color: "#9E9E9E" }} />
                    </Grid>
                    <Grid item style={{ flex: 1, minWidth: 0 }}>
                        <TextInput
                            inputProps={{ id: idNote5U }}
                            inputRef={(ref) => this.setFocusInput(ref, idNote5U)}
                            maxRows={5}
                            multiline={true}
                            style={{ fontStyle: "italic" }}
                            defaultValue={dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === 5 ? detail.Note5U : '' : detail.Note5U}
                            disabled={this.UserLevel !== 5}
                            onChange={(value) => this.onChange(value, "Note5U", detail)}
                        />
                    </Grid>
                </Grid>
                }
            </Grid>
            {data.IsCalculate === 1 &&
            <ButtonIcon
                circular
                viewType={"text"}
                color={"primary"}
                name={"Math"}
                style={{marginLeft: 10, cursor: "pointer"}}
                disabled={this.state.IsSave !== 1}
                onClick={() => this.getCalResult(detail)}
            />}
            </span>
        );
    };
    renderCriterionResults = (data, level) => {
        const {dataMasterData} = this.props;
        const EvaluationResult = dataMasterData && dataMasterData.EvaluationResult ? dataMasterData.EvaluationResult : 1;
        const calculateWidthCol = {
            maxWidth: `calc(100% / ${level})`,
            flexBasis: `calc(100% / ${level})`,
        };
        const col = Math.floor(12 / level);
        let pdLevel1 = {padding: "0"};
        let pdLevel2 = {padding: "0"};
        let pdLevel3 = {padding: "0"};
        let pdLevel4 = {padding: "0"};
        let pdLevel5 = {padding: "0"};

        if (level === 2) {
            pdLevel1 = {padding: "0 10px 0 0"};
            pdLevel2 = {padding: "0 0 0 10px"};
            pdLevel3 = {padding: "0"};
        }
        if (level === 3) {
            pdLevel1 = {padding: "0 10px 0 0"};
            pdLevel2 = {padding: "0 10px 0 10px"};
            pdLevel3 = {padding: "0 0 0 10px"};
        }
        if (level === 4) {
            pdLevel1 = {padding: "0 10px 0 0"};
            pdLevel2 = {padding: "0 10px 0 10px"};
            pdLevel3 = {padding: "0 10px 0 10px"};
            pdLevel4 = {padding: "0 0 0 10px"};
        }
        if (level === 5) {
            pdLevel1 = {padding: "0 10px 0 0"};
            pdLevel2 = {padding: "0 10px 0 10px"};
            pdLevel3 = {padding: "0 10px 0 10px"};
            pdLevel4 = {padding: "0 10px 0 10px"};
            pdLevel5 = {padding: "0 0 0 10px"};
        }

        pdLevel1 = {
            ...pdLevel1,
            ...calculateWidthCol,
        };
        pdLevel2 = {
            ...pdLevel2,
            ...calculateWidthCol,
        };
        pdLevel3 = {
            ...pdLevel3,
            ...calculateWidthCol,
        };
        pdLevel4 = {
            ...pdLevel4,
            ...calculateWidthCol,
        };
        pdLevel5 = {
            ...pdLevel5,
            ...calculateWidthCol,
        };

        return (
            <Grid
                key={Math.random().toString(36).substring(7)}
                container
                spacing={0}
                direction="row"
                justify="flex-end"
                alignItems="flex-start"
                style={{marginBottom: 20}}>
                <Grid item xs={12} style={{padding: "0 auto"}}>
                    <div>{Config.lang("Ket_qua_danh_gia")} <span style={{color: 'red'}}>*</span></div>
                    {data.Note && <div style={{whiteSpace: 'pre-line', fontStyle: "italic", fontSize: 13}}>{data.Note}</div>}
                </Grid>
                <hr style={{marginTop: 3, marginBottom: 5, width: "100%"}} />

                {level >= 1 &&
                <Grid item xs={col} style={pdLevel1}>
                    {this.renderCriterionCboResults( "ResultID1", this.UserLevel !== 1, 1)}
                </Grid>
                }
                {level >= 2 &&
                <Grid item xs={col} style={pdLevel2}>
                    {EvaluationResult > 1 && this.renderCriterionCboResults("ResultID2", this.UserLevel !== 2, 2)}
                </Grid>
                }
                {level >= 3 &&
                <Grid item xs={col} style={pdLevel3}>
                    {EvaluationResult > 2 && this.renderCriterionCboResults("ResultID3", this.UserLevel !== 3, 3)}
                </Grid>
                }
                {level >= 4 &&
                <Grid item xs={col} style={pdLevel4}>
                    {EvaluationResult > 3 && this.renderCriterionCboResults("ResultID4", this.UserLevel !== 4, 4)}
                </Grid>
                }
                {level >= 5 &&
                <Grid item xs={col} style={pdLevel5}>
                    {EvaluationResult > 4 && this.renderCriterionCboResults("ResultID5", this.UserLevel !== 5, 5)}
                </Grid>
                }
            </Grid>
        );
    };
    renderCriterionHeader = (EmployeeInfo, CriterionTitle, Note) => {
        const {dataMasterData} = this.props;
        const {isOpenPopover} = this.state;
        let dtSource = [];
        for(let i = 1; i <= dataMasterData.NumEvaluationLevel; i++) {
            dtSource.push({
                keyID: i,
                [`LabelLevel`]: dataMasterData[[`LabelLevel${i}`]],
                [`Reviewer`]: dataMasterData[`Reviewer${i}`],
                [`ResultProportion`]: dataMasterData[`ResultProportion${i}`]
            });
        };

        return (
            <Row>
                <Col xs={12} sm={4} md={4} lg={4}>
                    {EmployeeInfo && this.renderUserProfile(EmployeeInfo)}
                </Col>
                <Col xs={12} sm={6} md={6} lg={7}>
                    <div style={{textAlign:"center"}}><h2 className={"mgt0 mgb5"}>{CriterionTitle}</h2></div>
                    <div style={{textAlign:"center", fontStyle: "italic"}}><span>{Note}</span></div>
                </Col>
                <Col xs={12} sm={2} md={2} lg={1}>
                    <Button viewType={"outlined"} color={"primary"} ref={ref => (this.buttonResultRef = ref)}
                            className={"pdt2x pdb2x pdr4x pdl4x"}
                         onClick={() => {this.setState({isOpenPopover: true})}}>
                        <Typography color={"primary"} style={{whiteSpace: "nowrap"}} center type={"h4"}>{Config.lang("Ket_qua")}</Typography>
                        <Typography color={"primary"} center type={"h1"}>{dataMasterData?.ResultFinal || 0}</Typography>
                    </Button>
                    <Popover
                        open={isOpenPopover}
                        anchor={this.buttonResultRef}
                        onClose={() =>  this.setState({isOpenPopover: false})}
                        anchorOrigin= {{vertical: 'bottom', horizontal: 'right'}}
                        transformOrigin= {{vertical: 'top', horizontal: 'right'}}
                        style={{height: 272, width: 400}}
                    >
                        <div className={"mgt4x mgr4x mgb3x mgl4x"}>
                            <GridContainer
                                style={{border: 'none'}}
                                dataSource={dtSource}
                                keyExpr={'keyID'}
                                showBorders={false}
                                showColumnLines={false}
                                showColumnHeaders={true}
                                sorting={{
                                    mode: "none"
                                }}
                                noDataText={Config.lang("Khong_co_du_lieu")}
                            >
                                <Column
                                    caption={ Config.lang("Cap_danh_gia")}
                                    dataField={"LabelLevel"}
                                    width={200}
                                />
                                <Column
                                    caption={ Config.lang("Ket_qua")}
                                    dataField={"Reviewer"}
                                    width={80}
                                    alignment={"right"}
                                />
                                <Column
                                    caption={ Config.lang("Trong_so")}
                                    dataField={"ResultProportion"}
                                    width={50}
                                    alignment={"right"}
                                />
                            </GridContainer>
                        </div>
                    </Popover>
                </Col>
            </Row>
        );
    };
    renderEvaluationLevel = (dataMasterData, level) => {
        const { prevIsSubmit } = this.state;
        let LabelLevel1 = dataMasterData && dataMasterData.LabelLevel1 ? dataMasterData.LabelLevel1 :'';
        let LabelLevel2 = dataMasterData && dataMasterData.LabelLevel2 ? dataMasterData.LabelLevel2 :'';
        let LabelLevel3 = dataMasterData && dataMasterData.LabelLevel3 ? dataMasterData.LabelLevel3 :'';
        let LabelLevel4 = dataMasterData && dataMasterData.LabelLevel4 ? dataMasterData.LabelLevel4 :'';
        let LabelLevel5 = dataMasterData && dataMasterData.LabelLevel5 ? dataMasterData.LabelLevel5 :'';
        const calculateWidthCol = {
            maxWidth: `calc(100% / ${level})`,
            flexBasis: `calc(100% / ${level})`,
        };
        const col = Math.floor(12 / level);

        if (level === 1) {
            return null;
        }
        return (
            <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justify="center"
                style={{marginTop: 15}}>

                {level >= 1 &&
                <Grid item align="center" xs={col} className={"display_row align-center valign-middle"}
                    style={{backgroundColor: Config.coreTheme?.colors.blue12, padding: "10px 0", position: "relative",
                            ...calculateWidthCol,
                        }}
                >
                    <b style={{textTransform: "uppercase"}}>{LabelLevel1}</b>
                    {this.UserLevel === 2 &&
                        <ButtonIcon
                            circular
                            name={prevIsSubmit ? "Lock" : "Unlock"}
                            disabled={!prevIsSubmit}
                            size={"small"}
                            color={"primary"}
                            viewType={"text"}
                            style={{ position: "absolute", right: 0 }}
                            onClick={this.unlockPreviousLevel}
                        />
                    }
                </Grid>
                }
                {level >= 2 &&
                <Grid item align="center" xs={col} className={"display_row align-center valign-middle"}
                    style={{backgroundColor: Config.coreTheme?.colors.blue13, padding: "10px 0", position: "relative",
                            ...calculateWidthCol,
                        }}
                >
                    <b style={{textTransform: "uppercase"}}>{LabelLevel2}</b>
                    {this.UserLevel === 3 &&
                        <ButtonIcon
                            circular
                            name={prevIsSubmit ? "Lock" : "Unlock"}
                            disabled={!prevIsSubmit}
                            size={"small"}
                            color={"primary"}
                            viewType={"text"}
                            style={{ position: "absolute", right: 0 }}
                            onClick={this.unlockPreviousLevel}
                        />
                    }
                </Grid>
                }
                {level >= 3 &&
                <Grid item align="center" xs={col} className={"display_row align-center valign-middle"}
                    style={{backgroundColor: Config.coreTheme?.colors.blue14, padding: "10px 0", position: "relative",
                            ...calculateWidthCol,
                        }}
                >
                    <b style={{textTransform: "uppercase"}}>{LabelLevel3}</b>
                    {this.UserLevel === 4 &&
                        <ButtonIcon
                            circular
                            name={prevIsSubmit ? "Lock" : "Unlock"}
                            disabled={!prevIsSubmit}
                            size={"small"}
                            color={"primary"}
                            viewType={"text"}
                            style={{ position: "absolute", right: 0 }}
                            onClick={this.unlockPreviousLevel}
                        />
                    }
                </Grid>
                }
                {level >= 4 &&
                <Grid item align="center" xs={col} className={"display_row align-center valign-middle"}
                    style={{backgroundColor: Config.coreTheme?.colors.blue15, padding: "10px 0", position: "relative",
                            ...calculateWidthCol,
                        }}
                >
                    <b style={{textTransform: "uppercase"}}>{LabelLevel4}</b>
                    {this.UserLevel === 5 &&
                        <ButtonIcon
                            circular
                            name={prevIsSubmit ? "Lock" : "Unlock"}
                            disabled={!prevIsSubmit}
                            size={"small"}
                            color={"primary"}
                            viewType={"text"}
                            style={{ position: "absolute", right: 0 }}
                            onClick={this.unlockPreviousLevel}
                        />
                    }
                </Grid>
                }
                {level >= 5 &&
                <Grid item align="center" xs={col}
                    style={{backgroundColor: Config.coreTheme?.colors.blue16, padding: "10px 0", position: "relative",
                            ...calculateWidthCol,
                        }}
                >
                    <b style={{textTransform: "uppercase"}}>{LabelLevel5}</b>
                </Grid>
                }
            </Grid>
        );
    };
    renderCriterionFooter = (dataMasterData, level) => {
        const {  location} = this.props;
        const FormID = location?.state?.FormID ?? "";
        return (
                <Row>
                    <Col xs={12} >
                        {level >= 1 &&
                        <Col xs={12} >
                           <Typography  type="h4"> {Config.lang("Danh_gia_chung")}</Typography>
                        </Col>
                        }
                    </Col>
                {[...Array(5).keys()].map(idx=>{
                         if(level < 0) return null;
                         idx += 1
                         const LabelLevel = dataMasterData?.[`LabelLevel${idx}`]  || "";
                         const ReviewerName =dataMasterData?.[`ReviewerName${idx}`] || "";
                         const TimeEvaluatedLevel = Config.convertDate(dataMasterData?.[`TimeEvaluatedLevel${idx}`], "", "DD/MM/YYYY HH:mm:ss", true) || "";
                         const NameEvaluatedLevel = (idx === 1) ? "NoteEmployee" : `NoteEvaluatedLevel${idx}`
                         const NoteEvaluatedLevel = this.formData[`${NameEvaluatedLevel}`] ?? dataMasterData[`${NameEvaluatedLevel}`] ; 
                    return(
                        <Col  lg={12} key={idx}>
                            {level >= idx &&
                                <Row  lg={12} >
                                    <Col  lg={1}  xs={3}>
                                        <Typography className={"mgt1x"} type="h4" uppercase style={{display:"flex"}}>
                                        {LabelLevel}</Typography>
                                    </Col>
                                    <Col lg={4} xs={9} >  
                                        <Col  lg={12} >
                                            <Row   lg={12} >
                                                <Col  lg={5} >
                                                    <Typography  type="p2"> {Config.lang("Nguoi_danh_gia")}:</Typography>
                                                </Col>
                                                <Col  lg={7}>                 
                                                    <Typography type="p2" capitalize>{ReviewerName}</Typography>
                                                </Col>
                                            </Row> 
                                        </Col>
                                    <Col  lg={12} > 
                                        <Row   lg={12} >
                                            <Col  lg={5}>
                                                <Typography  type="p2" > {Config.lang("Thoi_gian")}:</Typography>
                                            </Col>
                                            <Col  lg={7}>
                                                <Typography  type="p2" >{TimeEvaluatedLevel}</Typography>
                                            </Col>  
                                        </Row>                                     
                                    </Col> 
                                    </Col>
                                    <Col  lg={7} xs={12}>
                                            <TextInput
                                            className={"mgb1x mgt1x"}
                                            maxRows={3}
                                            multiline={true}
                                            onChange={(e) => this.onChange(e?.target?.value || "", (idx  === 1 )? "NoteEmployee": `NoteEvaluatedLevel${idx}`)}
                                            disabled={this.UserLevel !== idx}
                                            defaultValue={dataMasterData?.NotViewOther && FormID === "W39F1010" ? this.UserLevel === idx ? NoteEvaluatedLevel : '' : NoteEvaluatedLevel}
                                        />
                                    </Col>
                                </Row>
                            }
                        </Col>
                    )
                })}
               
            </Row>
        );
    };
    renderHistoryApproval = (dataHistoryApproval) => {
        const {classes} = this.props;

        return (
            <Grid
                container
                direction="column"
                alignItems="flex-start"
                style={{marginTop: 15}}>

                <Grid container direction="row" className={"mgb15"}>
                    <Grid item xs={12}>
                        <div><b><i>* {Config.lang("Lich_su_duyet")}</i></b></div>
                    </Grid>
                </Grid>

                {dataHistoryApproval && dataHistoryApproval.map((data, idx) => {
                    let date = _.get(data, "ApprovalDate", "");
                    if (date && moment(date).isValid()) {
                        date = moment(date).format("DD/MM/YYYY kk:mm:ss");
                    }
                    const user = Config.getUser({UserID: data.ApproverID || ""});
                    return (
                        <Grid key={idx} container direction="row" alignItems={"flex-end"}
                              className={"mgb15"}>
                            <Grid item xs={3}>
                                <div className={"display_row align_center"}>
                                    <UserImage data={data} valueExpr={"UserID"} keyExpr={"ApproverID"} allowHover={false}/>
                                    <div>
                                        <div style={{ fontSize: '1.12rem' }}>
                                            <UserName data={data}
                                                      valueExpr={"UserID"}
                                                      keyExpr={"ApproverID"}
                                                      allowHover={false}
                                                      displayExpr={"UserName"}/> - {data.ApprovalStatusName || ""}
                                        </div>
                                        <div className={classes.dateInfo}>
                                            <Image src={require("../../../../assets/images/icon-calendar.svg")} />
                                            <div style={{ paddingLeft: "8px" }} className={"date-text-info"}>
                                                {date}
                                            </div>
                                        </div>
                                        {user && user.DutyName && <div>{user.DutyName || ""}</div>}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={9}>
                            <TextInput
                                    maxRows={3}
                                    multiline={true}
                                    disabled={true}
                                    style={{ marginTop: 2 }}
                                    defaultValue={data.ApprovalNotes || ""}
                                />
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };
    printDocument = async () => {
        if (!this.loaded) return;

        const isChecked = Object.keys(this.loaded).find(l => !this.loaded[l]);
        const input = this.contentContainer.current;
        if (!isChecked && input) {
            const svgElements = input.querySelectorAll('svg');
            svgElements.forEach(function(item) {
                item.setAttribute("width", item.getBoundingClientRect().width);
                item.setAttribute("height", item.getBoundingClientRect().height);
                item.style.width = null;
                item.style.height= null;
            });

            try {
                const canvasHtml = input.cloneNode(true);
                document.body.appendChild(canvasHtml);
                this.setState({loading2: true}, async ()=>{
                    const canvas  = await html2canvas(canvasHtml, {
                        scale:      1,
                        allowTaint: true,
                        useCORS:    true,
                        scrollX:    0,
                        scrollY:    -window.scrollY
                    });
                    canvasHtml.remove();
                    const canvasImage = canvas.toDataURL('image/png');
                    const myWindow = await window.open('', Config.lang("Danh_gia_nhan_vien"));
                    this.setState({loading2: false});
                    if (!myWindow) {
                        Config.popup2.show("INFO", Config.lang("Khong_the_mo_tab_moi"));
                        return false;
                    } else {
                        myWindow.document.write('<body style="margin:0" onload="window.print();window.close();"><img style="max-width: 100%" src="' + canvasImage + '" /></body>');
                        myWindow.document.close();
                        myWindow.focus();
                    }
                });
            } catch (e) {
                this.setState({loading2: false});
                Config.popup2.show("INFO", Config.lang("Co_loi_xay_ra_trong_qua_trinh_xu_ly"));
                return false;
            }
        } else {
            Config.popup2.show("INFO", Config.lang("Dang_chuan_bi_du_lieu_Xin_vui_long_cho"));
            return false;
        }
    };

    getCalResult = (detail) => {
        this.setState({loading2: true});
        const { Mode, EmployeeID, ApproveLevel, EvaluationVoucherID } = this.formData;
        const { EvaluationElementID = "" } = detail;
        const TransTypeID = this.TransTypeID || "";
        const dataDetail = [...this.dataDetail];
        let dataValue = [];
        if(!_.isEmpty(dataDetail)){
            dataDetail.forEach(item => {
                dataValue.push({
                    EvaluationElementID: item.EvaluationElementID,
                    Value: item.Value,
                    Value02: item.Value02,
                    Value03: item.Value03,
                    Value04: item.Value04,
                    Value05: item.Value05,
                })
            })
        }
        const param = {
            Mode,
            FormID: "W39F1011",
            EmployeeID,
            TransTypeID,
            ApproveLevel: ApproveLevel,
            EvaluationVoucherID,
            EvaluationElementID,
            data: JSON.stringify(dataValue)
        };

        this.props.W39F1011Actions.getResult(param, (error, data) => {
            this.setState({loading2: false});
            if (error) {
                Config.popup.show("ERROR", error || Config.lang("Loi_chua_xac_dinh"));
                return false;
            } else if (!Config.isEmpty(data, false)) {
                const result = {
                    Value: data?.Value || 0,
                    TypeName: data?.TypeName ?? ""
                };
                this.onChange(result, "Value", detail, true);
            }
        });
    }

    unlockPreviousLevel = () => {
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_mo_khoa_cho_cap_truoc_khong?"), () => {
            const params = {
                ...this.formData,
                Mode: 1,
                detail: this.dataDetail,
                arrAttachment: this._getAttachments(),
            }
            this.props.W39F1011Actions.save(params, (err, data) => {
                if (err) Config.popup.show("ERROR", err);
                if (data?.Status === 0) {
                    this.setState({ prevIsSubmit: 0 });
                    Config.notify.show("success", Config.lang("Mo_khoa_thanh_cong"), 2000);
                }
            });
        });
    };

    sendResult = () => {
        const params = {
            ...this.formData,
            Mode: 2,
            detail: this.dataDetail,
            arrAttachment: this._getAttachments(),
        }
        this.setState({ loading2: true });
        this.props.W39F1011Actions.save(params, (err, data) => {
            this.setState({ loading2: false });
            if (err) Config.popup.show("ERROR", err);
            if (data?.Status === 0) {
                const { location } = this.props;
                this.timeout = setTimeout(()=>{
                    this.isChangeForm = false;
                    const {Mode, Language, TransID, FormID, EmployeeID, EvaluationVoucherID} = this.formData;
                    const TransTypeID = this.TransTypeID;
                    this._removeCDN();
                    this.loaded = {
                        ...this.loaded,
                        loadMasterData: false,
                        loadCriterionGroup: false,
                        loadCriterionDetail: false,
                    };
                    this.canvasImage = null;
                    this.loadApi("loadMasterData", {FormID, Language, Mode, EvaluationVoucherID, TransID, EmployeeID, TransTypeID}, true);
                    this.loadApi("loadCriterionGroup", {}, true);
                    this.loadApi("loadCriterionDetail", {FormID, EvaluationVoucherID, TransID, EmployeeID, TransTypeID }, true);
                    this.loadApi("getRequiredFields", {FormID: 'W39F1011', ModuleID: '39', CheckMode: 0 }, false);
                    this.setState({ IsSave: 0 });
                    // setTimeout(() => {
                    //     this.isSaved = true;
                    // }, 500);
                }, 700);
                if(_.get(location,"state.dataReminder.KeyID", false)) this.ignoreReminder();
                Config.notify.show("success", Config.lang("Gui_ket_qua_thanh_cong"), 2000);
            }
        });
    };

    render() {
        const {
            dataOldAttachments,
            attachmentLoading,
            loading,
            loading2,
            uploading,

            CriterionTitle,
            Note,
            EmployeeInfo,
            errorMessage,
            IsSave,
            IsEvaluation,
            iPermission
        } = this.state;

        // check permission if open like popup
        const {isPopup} = this.props;
        if(iPermission === null) return null;
        if (iPermission === 0 && !!isPopup)
            return (
                <Typography type={'p2'} className={'pd10'} center>
                    {Config.lang('403_loi')}
                </Typography>
            );

        const { classes, dataMasterData, dataHistoryApproval } = this.props;
        const criterion = this.parseDataGroupCriterion();

        let level = 1;
        if (dataMasterData && dataMasterData.NumEvaluationLevel) {
            level = parseInt(dataMasterData.NumEvaluationLevel);
            level = level > 5 || level < 1 ? 1 : level;
        }
        let count = 0;
        const currentIsSubmit = dataMasterData?.[`IsSubmit${this.UserLevel}`] ?? 0;

        return (
            <>
                <ActionToolbar alignment="flex-end" title={Config.lang("Danh_gia_nhan_vien")} onBack={!isPopup ? this.onBack :  null}>
                    {!loading && !errorMessage && <>
                        <Button
                            size={"medium"}
                            color={"primary"}
                            startIcon={"Pdf"}
                            className={"mgr10"}
                            viewType={"outlined"}
                            label={Config.lang("Xuat_PDF")}
                            style={{ textTransform: "uppercase" }}
                            onClick={this.printDocument}
                        />
                        {dataMasterData?.SplitSaveAndSubmit
                            ? <>
                                <Button
                                    ref={ref => this.btnSaveRef["save"] = ref}
                                    disabled={!this.isChangeForm || (IsSave !== 1)}
                                    size={"medium"}
                                    color={"info"}
                                    startIcon={"SaveV2"}
                                    className={"mgr10"}
                                    viewType={"filled"}
                                    label={Config.lang("Luu")}
                                    style={{ textTransform: "uppercase" }}
                                    onClick={this.onSave}
                                />
                                <Button
                                    ref={ref => this.btnSendResultRef["sendResult"] = ref}
                                    disabled={!!!this.isSaved && (!!this.isChangeForm || IsSave !== 1 || !!currentIsSubmit || !IsEvaluation)}
                                    size={"medium"}
                                    color={"success"}
                                    startIcon={"FastForward"}
                                    viewType={"filled"}
                                    label={Config.lang("Gui_ket_qua")}
                                    style={{ textTransform: "uppercase" }}
                                    onClick={this.sendResult}
                                />
                            </>
                            : <Button
                                ref={ref => this.btnSaveRef["save"] = ref}
                                disabled={!this.isChangeForm || (IsSave !== 1)}
                                size={"medium"}
                                color={"info"}
                                startIcon={"SaveV2"}
                                viewType={"filled"}
                                label={Config.lang("Luu")}
                                style={{ textTransform: "uppercase" }}
                                onClick={this.onSave}
                            />
                        }
                    </>}
                </ActionToolbar>

                <LoadPanel
                    position={{my: 'center', of: null}}
                    visible={loading || loading2}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                />
                {!loading && errorMessage &&
                <Container maxWidth="lg" style={{ paddingTop: 20, marginLeft: 0 }}>
                    <center>{errorMessage}</center>
                </Container>
                }
                {!loading && !errorMessage && <>
                <Container innerRef={this.contentContainer} maxWidth="lg" style={{paddingTop: 20, marginLeft: 0, width: "100%"}}>
                    {EmployeeInfo && CriterionTitle && this.renderCriterionHeader(EmployeeInfo, CriterionTitle, Note)}

                    {dataMasterData && this.renderEvaluationLevel(dataMasterData, level)}

                    {criterion && criterion.map((o, i) => {
                        const ListCriterionDetail = o.ListCriterionDetail && o.ListCriterionDetail.filter(c => c.IsMiddleEvaluationElement === 0);
                        if (ListCriterionDetail.length <= 0) return null;
                        const sectionNum = convertToRoman(count + 1);
                        count++;
                        return (
                            <FormControl key={i} component={"fieldset"} className={classes.fieldset}>
                                <Row>
                                    <Col xs={12}>
                                        {sectionNum}. {o.AppCriterionGroupNameU} &nbsp;
                                        <Col xs={1}>
                                            {o.AppCriterionGroupDescU &&
                                                <Tooltip title={o.AppCriterionGroupDescU} placement="right">
                                                    <sup>
                                                        <i className="fa fa-info-circle mgt2x" aria-hidden="true" style={{color: "#9E9E9E"}}></i>
                                                    </sup>
                                                </Tooltip>}
                                        </Col>
                                    </Col>
                                </Row>

                                {o.ListCriterionDetail && o.ListCriterionDetail.map((c) => {
                                    return c.IsMiddleEvaluationElement === 0 ? this.renderCriterionDetail(c, level) : null;
                                })}
                            </FormControl>);
                    })}

                    {dataMasterData && dataMasterData.IsEvaluateContract === 1 && <FormControl component={"fieldset"} className={classes.fieldset}>
                        <Row>
                            <Col xs={12}>
                                {this.renderCriterionResults(dataMasterData, level)}
                            </Col>
                        </Row>
                    </FormControl>}

                    {dataMasterData && this.renderCriterionFooter(dataMasterData, level)}

                    {dataHistoryApproval && dataHistoryApproval.length > 0 && this.renderHistoryApproval(dataHistoryApproval, level)}
                </Container>
                {dataMasterData &&
                <FormGroup>
                    <Row style={{marginTop: 30, width: '100%'}}>
                        <Col xs={12}>
                            <Attachments
                                isAttachInfo
                                ref={(ref) => (this.attRef = ref)}
                                showButton={true}
                                files={dataOldAttachments}
                                disabled={uploading}
                                maxLength={5}
                                uploading={attachmentLoading}
                                onUploading={this.onUploading}
                                onChanged={this.onChangeAttachments}
                            />
                        </Col>
                    </Row>
                </FormGroup>}
                {dataMasterData &&
                <FormGroup style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "flex-end"}}>
                <Button
                    size={"medium"}
                    color={"primary"}
                    startIcon={"Pdf"}
                    className={"mgr10"}
                    viewType={"outlined"}
                    label={Config.lang("Xuat_PDF")}
                    style={{ textTransform: "uppercase" }}
                    onClick={this.printDocument}
                />
                {dataMasterData?.SplitSaveAndSubmit
                    ? <>
                        <Button
                            ref={ref => this.btnSaveRef["save1"] = ref}
                            disabled={!this.isChangeForm || (IsSave !== 1)}
                            size={"medium"}
                            color={"info"}
                            startIcon={"SaveV2"}
                            className={"mgr10"}
                            viewType={"filled"}
                            label={Config.lang("Luu")}
                            style={{ textTransform: "uppercase" }}
                            onClick={this.onSave}
                        />
                        <Button
                            ref={ref => this.btnSendResultRef["sendResult1"] = ref}
                            disabled={!!!this.isSaved && (!!this.isChangeForm || IsSave !== 1 || !!currentIsSubmit || !IsEvaluation)}
                            size={"medium"}
                            color={"success"}
                            startIcon={"FastForward"}
                            viewType={"filled"}
                            label={Config.lang("Gui_ket_qua")}
                            style={{ textTransform: "uppercase" }}
                            onClick={this.sendResult}
                        />
                    </>
                    : <Button
                        ref={ref => this.btnSaveRef["save1"] = ref}
                        disabled={!this.isChangeForm || (IsSave !== 1)}
                        size={"medium"}
                        color={"info"}
                        startIcon={"SaveV2"}
                        viewType={"filled"}
                        label={Config.lang("Luu")}
                        style={{ textTransform: "uppercase" }}
                        onClick={this.onSave}
                    />
                }
                <div id={"print"} className={"hidden"} />
                </FormGroup>
                    }
                </>}
            </>
        );
    }
}
export default compose(
    connect(
        state => ({
            dataMasterData: state.W39F1011.dataMasterData,
            dataCriterionGroup: state.W39F1011.dataCriterionGroup,
            dataCriterionCombo: state.W39F1011.dataCriterionCombo,
            dataCriterionDetail: state.W39F1011.dataCriterionDetail,
            dataComboResults: state.W39F1011.dataComboResults,
            dataSaveResult: state.W39F1011.dataSaveResult,
            dataHistoryApproval: state.W39F1011.dataHistoryApproval,
            getRequiredFields: state.general.getRequiredFields,
        }),
        dispatch => ({
            mainActions: bindActionCreators(mainActions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
            W39F1011Actions: bindActionCreators(W39F1011Actions, dispatch)
        })
    ),
    withStyles(styles, { withTheme: true })
)(W39F1011);
