/**
 * @copyright 2020 @ DigiNet
 * @author CanhToan
 * @create 06/30/2020
 * @update 06/30/2020
 * @Example
 */

import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import Config from "../../../../config";
import { Col, DateRangePicker, Dropdown } from "diginet-core-ui/components";
import { browserHistory } from "react-router";

import * as w39F1000Actions from "../../../../redux/W3X/W39F1000/W39F1000_actions";
import ListApproval from "../../../common/list-approval";

class W39F1000 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataGrid: null,
        };
    }
    onAdd = () => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W39F1005",
            state: { mode: "add" },
        });
    };

    onView = e => {
        const { data } = e.row;
        browserHistory.push({
            pathname: Config.getRootPath() + "W39F1005",
            state: { mode: data.AppStatusID ? "view" : "edit", data: data },
        });
    };

    onDelete = (param, cb) => {
        const params = {
            FormID: param.FormID,
            TransID: param.EvaluationVoucherID,
            Mode: 0,
        };
        return this.props.w39F1000Actions.deleteDataGrid(params, cb);
    };

    renderFilter = (dataFilter, filterChange) => {
        const { getCboAppStatus } = this.props;
        return (
            <>
                <Col xs={12}>
                    <DateRangePicker
                        viewType={"outlined"}
                        label={Config.lang("Ngay")}
                        onChange={e => filterChange("PerformanceDate", e, "Date")}
                        clearAble
                        placeholder={"DD/MM/YYYY"}
                        returnFormat={"YYYY-MM-DD"}
                        controls
                        defaultValue={[dataFilter.PerformanceDateFrom, dataFilter.PerformanceDateTo]}
                    />
                </Col>
                <Col xs={12}>
                    <Dropdown
                        viewType={"outlined"}
                        label={Config.lang("Trang_thai_duyet")}
                        dataSource={getCboAppStatus}
                        displayExpr={"AppStatusName"}
                        valueExpr={"AppStatusID"}
                        onChange={e => filterChange("AppStatusID", e)}
                        clearAble
                        defaultValue={dataFilter.AppStatusID === "%" ? "" : dataFilter.AppStatusID}
                    />
                </Col>
            </>
        );
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { dataGrid } = this.state;
        Config.callChildForm(
            {
                FormID: "W39F1000",
                // ID: voucher_id,
                keyExpr: "EvaluationVoucherID",
                data: dataGrid && dataGrid.rows ? dataGrid.rows : [],
                onLoad: params => this.gridRef?.loadGrid({ VoucherID: params && params.ID }),
                onAction: (e, data) => this.onView({ row: { data: data } }),
            },
            this.props
        );
    }

    getInfo = () => {
        const { location } = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get("voucher_id")) {
            return { VoucherID: url.get("voucher_id") };
        } else if (location && location.state) {
            return {
                VoucherID: location.state.voucher_id,
            };
        } else {
            return {};
        }
    };

    render() {
        return (
            <ListApproval
                ref={this.gridRef}
                title={Config.lang("Danh_gia_Nhan_vien")}
                onAdd={this.onAdd}
                isLoadPermission
                keyExpr={"EvaluationVoucherID"}
                renderFilter={this.renderFilter}
                FormID={"W39F1000"}
                action={{
                    loadGrid: (param, cb) => {
                        const params = {
                            ...param,
                            TransID: param.VoucherID || "",
                            DivisionID: Config.getDivisionID(),
                            TranMonth: Config.getHRTransMonth(),
                            TranYear: Config.getHRTransYear(),
                            AppStatusID: Number.isInteger(param.AppStatusID) ? param.AppStatusID.toString() : "%",
                        };
                        return this.props.w39F1000Actions.loadDataGrid(params, (err, data) => {
                            cb && cb(err, data);
                        });
                    },
                    loadStatus: this.props.generalActions.getCboAppStatus,
                }}
                renderInfo={{
                    [Config.lang("Loai_danh_gia")]: "TransTypeName",
                    [Config.lang("Chu_ky")]: "PerformancePeriod",
                    [Config.lang("Ghi_chu")]: "Note",
                }}
                onLoadedGrid={dataGrid => {
                    this.setState({ dataGrid: dataGrid });
                }}
                renderAction={() => {
                    return {
                        View: {
                            action: this.onView,
                        },
                        Delete: {
                            action: this.onDelete,
                        },
                    };
                }}
                onDbCellClick={this.onView}
            />
        );
    }
}

export default compose(
    connect(
        state => ({
            getCboAppStatus: state.general.getCboAppStatus,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w39F1000Actions: bindActionCreators(w39F1000Actions, dispatch),
        })
    )
)(W39F1000);
